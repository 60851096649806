//import axios from "axios";

import axios from './axiosConfig';


import { createBrowserHistory } from "history";

//import { WonderPush } from 'react-wonderpush';
//window.WonderPush = window.WonderPush || [];
//WonderPush.push(['setUserId', "THIS_USER_ID"]);



//const API_URL = "http://localhost:5551";
const API_URL = "https://api.easyebook.io";

const history = createBrowserHistory();


export const cancelSubscription = () => {
  return (dispatch) => {
    axios.get(API_URL + "/cancel_subscription").then(({ data }) => {
      dispatch({ type: "BALANCE", d: data['credits'], ispaying: data['ispaying'], willcancel: data['willcancel'] });

    });
  };
};

export const subscribe = () => {
  return (dispatch) => {
    axios.get(API_URL + "/subscribe").then(({ data }) => {
      dispatch({ type: "REDIRECT_NOW", d: data });
    });
  };
};




export const createEbook = (selectedLanguage, author, selectedContentType, description, script, chapters) => {
  return (dispatch) => {
    axios
      .post(API_URL + "/create_ebook", { selectedLanguage, author, selectedContentType, description, script, chapters })
      .then(({ data }) => {
        dispatch({ type: "STEP_DATA_3", d: { status: "OK" } });
      })
      .catch((error) => {
        dispatch({ type: "ERROR_IN_STEP", what: "Internal error, please try again." });
      });
  };
};


export const sendStep = (step, v1, v2, v3, v4, v5) => {
  return (dispatch) => {

    dispatch({ type: "RESET_ERRORS" });


    axios
      .post(API_URL + "/do_step", step === 0 ? { step, value: v1 } : step === 1 ? { step, value: v1, value2: v2 } : step === 2 ? { step, value: v1, value2: v2, value3: v3 } : { step, value: v1, value2: v2, value3: v3, value4: v5, value5: v4 })
      .then(({ data }) => {
        //dispatch({ type: "SENT_MESSSAGE" });
        //dispatch({ type: "UPDATEPROF", d: data[0] });
        if (data.step === 0) {

          dispatch({ type: "STEP_DATA_0", d: data.response });

        }

        else if (data.step === 1) {

          dispatch({ type: "STEP_DATA_1", d: data.response });

        }

        else if (data.step === 2) {

          dispatch({ type: "STEP_DATA_2", d: data.response });

        }

        else if (data.step === 3) {

          dispatch({ type: "STEP_DATA_3", d: { status: "OK" } });

        }

        // manage this condition HERE!!


      }).catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 400) {

            dispatch({ type: "ERROR_IN_STEP", what: "Internal error, please try again." });

          }

          else {
            dispatch({ type: "ERROR_IN_STEP", what: "Internal error, please try again." });

          }
        }
      });
  };
};



export const editEbook_admin = (id, content, isAdmin) => {
  return (dispatch) => {

    axios
      .post(API_URL + "/edit_book_admin", { id, content })
      .then(({ data }) => {

        dispatch({ type: "BOOK_EDIT", d: true });

      }).catch((error) => {

        dispatch({ type: "BOOK_EDIT", d: true });

      });
  };
};


export const editEbook = (id, content, isAdmin) => {
  return (dispatch) => {

    axios
      .post(API_URL + "/edit_book", { id, content })
      .then(({ data }) => {

        dispatch({ type: "BOOK_EDIT", d: true });

      }).catch((error) => {

        dispatch({ type: "BOOK_EDIT", d: true });

      });
  };
};


export const saveSettings = (company, full_name, address, country, canva_api) => {
  return (dispatch) => {

    axios
      .post(API_URL + "/save_settings", { company, full_name, address, country, canva_api })
      .then(({ data }) => {

        dispatch({ type: "SAVED_SETTINGS", d: true });

      }).catch((error) => {

        dispatch({ type: "SAVED_SETTINGS", d: true });

      });
  };
};





export const saveSettings_admin = (company, full_name, address, country, credits, is_admin, id) => {
  return (dispatch) => {

    axios
      .post(API_URL + "/save_settings_admin", { company, full_name, address, country, credits, is_admin, id })
      .then(({ data }) => {

        dispatch({ type: "SAVED_SETTINGS", d: true });

      }).catch((error) => {

        dispatch({ type: "SAVED_SETTINGS", d: true });

      });
  };
};


export const saveSettings_admin2 = (chapters, outlines, price, maxwords) => {
  return (dispatch) => {

    axios
      .post(API_URL + "/save_settings_admin2", { chapters, outlines, price, maxwords })
      .then(({ data }) => {

        dispatch({ type: "SAVED_SETTINGS", d: true });

      }).catch((error) => {

        dispatch({ type: "SAVED_SETTINGS", d: true });

      });
  };
};


export const getSettingsAdmin2 = () => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_general_settings")
      .then(({ data }) => {
        dispatch({ type: "GENERAL_SETTINGS", d: data });
      });
  };
};


export const getSettingsAdmin = (id) => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_settings_admin?id=" + id)
      .then(({ data }) => {
        dispatch({ type: "SETTINGS_ADMIN", d: data });
      });
  };
};

export const getSettings = () => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_settings")
      .then(({ data }) => {
        dispatch({ type: "SETTINGS", d: data });
      });
  };
};

export const getBalance = () => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_balance")
      .then(({ data }) => {
        dispatch({ type: "BALANCE", d: data['credits'], ispaying: data['ispaying'], willcancel: data['willcancel'] });
      })
  };
};


export const getPrice = () => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_price")
      .then(({ data }) => {
        dispatch({ type: "PRICE", d: data });
      })
  };
};


export const getStripe = (price, amount) => {
  return (dispatch) => {
    axios
      .get(API_URL + "/buy_credit2?quantity=" + amount + "&amount=" + price)
      .then(({ data }) => {
        dispatch({ type: "REDIRECT_NOW", d: data });
      })
  };
};





export const getTXs = (a, b) => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_billing")
      .then(({ data }) => {
        dispatch({ type: "TXS", d: data.response });
      });
  };
};

export const getTXsAdmin = (a, b) => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_billing_admin")
      .then(({ data }) => {
        dispatch({ type: "TXS_ADMIN", d: data.response });
      });
  };
};


export const getEbooks = (a, b) => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_ebooks")
      .then(({ data }) => {
        dispatch({ type: "EBOOKS", d: data.response });
      });
  };
};

export const getEbooks_admin = (id) => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_ebooks_admin?id=" + id)
      .then(({ data }) => {
        dispatch({ type: "EBOOKS_ADMIN", d: data.response });
      });
  };
};



export const getLogs = (a, b) => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_logs")
      .then(({ data }) => {
        dispatch({ type: "LOGS", d: data.response });
      });
  };
};

export const getUsersAdmin = (a, b) => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_users_admin")
      .then(({ data }) => {
        dispatch({ type: "USERS", d: data.response });
      });
  };
};



export const getAnalytics = (arr) => {




  return (dispatch) => {



    if (!arr || arr.length < 1) {
      return dispatch({ type: "STATS", d: [] });
    }

    let from = arr[0];
    let to = arr[1];

    axios
      .get(API_URL + "/get_analytics?from=" + from + "&to=" + to)
      .then(({ data }) => {
        dispatch({ type: "ANALYTICS", d: data });
      });
  };
};

export const getStats = (a, b) => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_stats")
      .then(({ data }) => {
        dispatch({ type: "STATS", d: data });
      });
  };
};


export const getEbook = (id) => {
  return (dispatch) => {
    axios
      .get(API_URL + "/get_ebook?id=" + id)
      .then(({ data }) => {
        dispatch({ type: "EBOOK", d: data });
      });
  };
};





export const getUser = (id) => {
  return (dispatch) => {
    axios
      .get(
        API_URL + "/getuser?id=" + id
      )
      .then(({ data }) => {
        dispatch({ type: "USER_PROFILE_DATA", d: data });
      });
  };
};






export const recover = (username) => {
  return (dispatch) => {

    dispatch({ type: "RESET_ERRORS" });

    axios
      .post(API_URL + "/recovery", { email: username })
      .then(({ data }) => {
        if (data.error) {
          dispatch({ type: "ERROR_RECOVERY" });
        } else {
          dispatch({ type: "RECOVERED", recovered: true });
        }
      });
  };
};



export const loginGoogle = (cred) => {
  return (dispatch) => {


    dispatch({ type: "RESET_ERRORS" });

    //alert(pwd);

    axios
      // .get(API_URL + "/check_username?username=" + username)
      .post(API_URL + "/auth/google", { token: cred })

      .then(({ data }) => {

        //alert(data.token);

        dispatch({ type: "LOG_USER_IN", token: data.tokenm });
        history.push('/ebooks');


      }).catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 400) {

            dispatch({ type: "ERROR_EMAIL_NOT_EXIST" });

          }

          else if (error.response.status === 401) {

            dispatch({ type: "ERROR_PWD_LOGIN" });

          }


          else if (error.response.status === 402) {

            dispatch({ type: "ERROR_LOGGIN_IN" });

          }

        };

      });

  };
};



export const login = (username, pwd) => {
  return (dispatch) => {


    dispatch({ type: "RESET_ERRORS" });

    //alert(pwd);

    axios
      // .get(API_URL + "/check_username?username=" + username)
      .post(API_URL + "/login", { email: username, password: pwd })

      .then(({ data }) => {

        //alert(data.token);

        dispatch({ type: "LOG_USER_IN", token: data.token });
        history.push('/ebooks');


      }).catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 400) {

            dispatch({ type: "ERROR_EMAIL_NOT_EXIST" });

          }

          else if (error.response.status === 401) {

            dispatch({ type: "ERROR_PWD_LOGIN" });

          }


          else if (error.response.status === 402) {

            dispatch({ type: "ERROR_LOGGIN_IN" });

          }

        };

      });

  };
};


export const loginforced = (id) => {
  return (dispatch) => {

    //dispatch({ type: "RESET_ERRORS" });
    //autologin
    dispatch({ type: "LOG_USER_IN", token: id });
    //history.push('/ebooks');
    window.location.href = "/ebooks"
  };
};




export const resetCreation = () => {
  console.log("resetting...");
  return (dispatch) => {

    dispatch({ type: "RESET_CREATION" });

  }
};




export const resetErrors = () => {
  return (dispatch) => {

    dispatch({ type: "RESET_ERRORS" });

  }
};


export const checkUsername = (username, name, password1, password2) => {
  return (dispatch) => {


    dispatch({ type: "RESET_ERRORS" });


    axios
      // .get(API_URL + "/check_username?username=" + username)
      .post(API_URL + "/register", { email: username, name, password1, password2 })

      .then(({ data }) => {

        //alert(data.token);

        dispatch({ type: "LOG_USER_IN", token: data.token });
        history.push('/ebooks');


      }).catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 400) {

            dispatch({ type: "ERROR_LOGGING_USERNAME" });

          }

          else if (error.response.status === 401) {

            dispatch({ type: "ERROR_PWD" });

          }


          else if (error.response.status === 402) {

            dispatch({ type: "ERROR_EMAIL" });

          }

        };

      });

  }

};


export const logOut = (pwd) => {
  return (dispatch) => {
    dispatch({ type: "LOGGEDOUT" });
  };
};
