import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getEbooks } from "../store/actions/homeActions";
import { Redirect } from 'react-router-dom';
import { Link } from "react-router-dom";
import DataTable from 'react-data-table-component';
import moment from 'moment';
import LOADER from "../assets/images/loader.svg";
import { useHistory } from 'react-router-dom';


import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import io from 'socket.io-client';


//const URL = "http://localhost:5552";
const URL = "https://ws.easyebook.io";

const socket = io(URL, { autoConnect: false });

function MyEbooks(props) {

  const { token, ebooks, getEbooksFN, page, limit, loading } = props;

  const history = useHistory();

  const [clicked_ebook, setclicked_ebook] = useState(null);
  //const [clicked_analytics, setclicked_analytics] = useState([]);


  //const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const canvaRef = useRef();
  //const anaRef = useRef();


  useEffect(() => {

    socket.auth = { username: token };
    socket.connect();

    getEbooksFN(page, limit);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ebooks]);


  const handlePageChange = page => {
    getEbooksFN(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    getEbooksFN(page, newPerPage);
  };



  useEffect(() => {
    socket.on('connect', () => {
      //setIsConnected(true);
      //alert(1);
    });

    socket.on('disconnect', () => {
      //setIsConnected(false);
    });

    socket.on('pong', () => {
      //setLastPong(new Date().toISOString());
    });


    socket.on('pm', () => {

      getEbooksFN(null, null);
      //alert(2);


    });


    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('pong');
    };


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const columns = [
    {
      name: 'Name',
      selector: row => row.status === 0 ? (<img alt="loader" src={LOADER} />) : (<p style={{ fontSize: "1.2em" }}>{row.title}</p>),
      sortable: true,
      width: '40%',
    },
    {
      name: 'Created at',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{moment(row.dt).format('MMMM D, YYYY [at] h:mma')}</p>),
      sortable: true,
      width: '20%',
    },
    {
      name: 'Status',
      selector: row => row.status === 0 ? (<img alt="loader" src={LOADER} />) : row.status === 1 ? (<span style={{ fontSize: "1.2em", color: "#79CA27", fontWeight: 600 }}>Ready</span>) : row.status === 3 ? (<span style={{ fontSize: "1.2em", color: "#000", fontWeight: 600 }}>Waiting</span>) : (<span style={{ fontSize: "1.2em", color: "#D0021B", fontWeight: 600 }}>Failed</span>),
      sortable: true,
      width: '10%',
    },
    {
      name: 'Actions',
      width: '30%',
      selector: row => row.status === 1 ? (<p><button style={{
        padding: "5px 10px",
        fontSize: "1rem",
        fontWeight: "600",
        border: 0,
        color: "#fff",
        background: "#000",
        borderRadius: "3px",
      }} onClick={() => {
        history.push({
          pathname: '/edit/' + row.id,
        })
      }}>Edit</button>{" "}<button style={{
        padding: "5px 10px",
        fontSize: "1rem",
        fontWeight: "600",
        border: 0,
        color: "#000",
        background: "#FECB00",
        borderRadius: "3px",
      }} onClick={() => {
        setclicked_ebook(row.canva_id)
        canvaRef?.current?.open();
      }}>Open in Canva</button>{" "}{/*<button style={{
        padding: "5px 10px",
        fontSize: "1rem",
        fontWeight: "400",
        border: 0,
        color: "#000",
        background: "#FECB00",
        borderRadius: "3px",
      }} onClick={() => {
        setclicked_analytics([row.title, row.clicks])
        anaRef.current.open();
      }}>View Analytics</button>*/}</p>) : null,
      sortable: true,
    }
  ];


  return token ? (
    <div className="container" style={{ background: "#fff", marginTop: "90px" }}>

      {ebooks && ebooks.some(ebook => ebook.status === 3) ? <p style={{ margin: 13, background: "#FECB00", padding: "10px", borderRadius: "10px", color: "#000" }}>        You have ebooks that cannot be generated because of your low credit balance. Click <Link style={{ fontWeight: 600, color: "#000" }} to="/billing">here</Link> to top up now.</p> : null}
      <div className="columns" style={{ margin: 13, background: "white", borderRadius: "3px" }}>
        <div
          className="column"
          style={{
            color: "black",
            padding: "10px !important",
            //border: "2px solid #FECB00",
            borderRadius: "10px",
            boxShadow: '10px 10px 60px -16px rgba(0,0,0,0.2)',


          }}
        >

          {ebooks === undefined || !ebooks || ebooks.length < 1 ? <p>You haven't created any ebook yet. Click <Link to="/create">here</Link> to create one now.</p> : <DataTable
            title="My Ebooks"
            columns={columns}
            data={ebooks}
            progressPending={loading}
            pagination
            //paginationServer
            //paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}


            paginationRowsPerPageOptions={[10, 20, 30]}
            paginationPerPage={10} // Set the default number of rows per page
            paginationTotalRows={ebooks.length} // Total number of rows in your data
            paginationDefaultPage={1}



          />}

        </div>
      </div>
      <div style={{ height: "20px" }}></div>
      <div className="columns" style={{ margin: 13 }}>
        <p style={{
          color: "black",
        }}>© 2025 EasyEbook</p>
      </div>




      <Popup
        ref={canvaRef}
        contentStyle={{/*margin:"auto 10px"*/ }}
      >

        <div style={{ padding: "30px 20px" }}>
          <div style={{ width: "300px" }}><p style={{ color: "#333", fontWeight: "600", textAlign: "center", fontSize: "1em" }}>Open in Canva
          </p>
            <div style={{ height: "15px" }}></div>

            {/* <p style={{ color: "#333", fontWeight: "400", textAlign: "center", fontSize: "1em" }}>
              To open this eBook in Canva, first open a new Canva document. On the left-hand panel, search for the "easyebook" app and paste the following code into the input field. Finally, click on the "Open eBook" button in Canva.
      </p>*/}

            <p style={{ color: "#333", fontWeight: "400", textAlign: "center", fontSize: "1em" }}>
              To open this ebook in Canva, first <a rel="noreferrer" href="https://www.canva.com/login/?redirect=%2Fdesign%3Fcreate%26type%3DTABQqplf4ko%26ui%3DeyJFIjp7IkE_IjoiTiIsIlMiOiJBQUdIYzZJY2xLTSIsIlQiOjN9fQ" target="_blank">click here</a>. Then, copy the ebook ID below and paste it into the Canva app.
            </p>
            <div style={{ height: "15px" }}></div>


            <p style={{ color: "#333", fontWeight: "400", textAlign: "center", fontSize: "1em" }}>
              <input type="text" disabled value={clicked_ebook} style={{ width: "300px", height: "50px", padding: "0px 10px" }} />
            </p>

          </div>

          <div style={{ height: "15px" }}></div>

          <div className="" style={{ maxWidth: "300px", color: "white", fontSize: "0.8em", textAlign: "center", fontWeight: 600 }}> <button className="" style={{
            border: 0,
            padding: "5px 15px", lineHeight: "30px", background: "#48c774", color: "white", borderRadius: "3px"
          }} onClick={() => {
            canvaRef.current.close();

          }}><b>Close</b></button></div>


        </div>
      </Popup>



      {/* <Popup
        ref={anaRef}
      >

        <div style={{ padding: "30px 20px" }}>
          <div style={{ width: "300px" }}><p style={{ color: "#333", fontWeight: "600", textAlign: "center", fontSize: "1em" }}>eBook Analytics
          </p>
            <div style={{ height: "15px" }}></div>

            <p style={{ color: "#333", fontWeight: "400", textAlign: "center", fontSize: "1em" }}>
              Your eBook <span style={{ color: "#A9A9A9" }}>{clicked_analytics[0]}</span> has received <b>{clicked_analytics[1] || 0}</b> {clicked_analytics[1] === 1 ? "click" : "clicks"} so far.
            </p>

          </div>

          <div style={{ height: "15px" }}></div>

          <div className="" style={{ maxWidth: "300px", color: "white", fontSize: "0.8em", textAlign: "center", fontWeight: 600 }}> <button className="" style={{
            border: 0,
            padding: "5px 15px", lineHeight: "30px", background: "#48c774", color: "white", borderRadius: "3px"
          }} onClick={() => {
            anaRef.current.close();

          }}><b>Close</b></button></div>


        </div>
      </Popup>*/}


    </div >) : <Redirect to="/login" />;

}

const mapStateToProps = (state) => {
  return {

    token: state.home.token,
    ebooks: state.home.ebooks,
    total: state.home.total,
    page: state.home.page,
    limit: state.home.limit,
    loading: state.home.loading,
    balance: state.home.balance


  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEbooksFN: (a, b) =>
      dispatch(getEbooks(a, b)),
  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(MyEbooks);
