import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getEbook, resetCreation, editEbook } from "../store/actions/homeActions";
import { Redirect } from 'react-router-dom';
import { EditorState, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import htmlToDraft from 'html-to-draftjs';


import { useHistory } from 'react-router-dom';

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';


function Ebook(props) {



  const { token, ebook, getEbookFN, match, edited, resetCreationFn, editEbookFn } = props;

  const [editorState, seteditorState] = useState(EditorState.createEmpty());
  const editorRef = useRef(null);
  const canvaRef = useRef();
  const canvaRef2 = useRef();

  const [redirect_url, setredirect_url] = useState(null);

  const history = useHistory();


  const [clicked_ebook, setclicked_ebook] = useState(null);

  /*
  const CustomButton = ({ onClick }) => {



    return (
      <div
        className="rdw-option-wrapper"
        onClick={onClick}
      >
        Add Contact Us Button
      </div>
    );
  };

*/

  const onEditorStateChange = editorState => {
    seteditorState(editorState);
  };


  const toolbarOptions = {
    options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'history'], // Customize which options to show
    inline: {
      options: ['bold', 'italic', 'underline'], // Only show bold, italic, and underline
    },
    blockType: {
      inDropdown: true,
      options: ['Normal', 'H1', 'H2', 'H3', 'Blockquote'],
    },
    list: {
      options: ['unordered', 'ordered'],
    },
    textAlign: {
      options: ['left', 'center', 'right'],
    },
    link: {
      options: [],
    },
    history: {
      options: ['undo', 'redo'],
    },
  };



  const appendHtmlToEditor = (editorState, seteditorState, url, ebokk) => {

    // const editorState = getEditorState();
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();

    // HTML content to be added
    const html = '<a href="http://localhost:5551/go?ebook=' + ebokk + '&url=' + encodeURIComponent(url) + '" style="padding:15px 10px;font-size:1rem;font-weight:400;border: 0;color:#000;background:#FECB00;border-radius:3px;">Contact us</a>';
    const contentBlock = htmlToDraft(html);
    const newContentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);

    // Get the current content as a block map
    const blockMap = contentState.getBlockMap().merge(newContentState.getBlockMap());

    // Create new content state with appended content block
    const finalContentState = contentState.merge({
      blockMap: blockMap,
      selectionAfter: selectionState,
    });

    // Create a new editor state with the modified content
    const newEditorState = EditorState.push(editorState, finalContentState, 'insert-fragment');
    seteditorState(newEditorState);

    const editorContent = document.querySelector('.editor-content');
    setTimeout(() => {
      if (editorContent) {
        editorContent.scrollTop = editorContent.scrollHeight + 100;
      }
    }, 500);


  };



  const setContent = (html) => {
    /*
    const contentState = convertFromRaw(content);
    const editorState = EditorState.createWithContent(contentState);
    seteditorState(editorState);
    */

    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      seteditorState(editorState);
    }


  };

  const getContent = () => {
    const contentState = editorState.getCurrentContent();
    let de = stateToHTML(contentState);
    //console.log(de);
    return de;
  };



  useEffect(() => {

    getEbookFN(match.params.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {

    if (edited) {

      resetCreationFn();
      history.push({
        pathname: '/ebooks',
      })

    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edited]);


  useEffect(() => {

    if (ebook && ebook.content && ebook.content !== undefined) {
      setContent(ebook.content);
      setclicked_ebook(ebook.canva_id);

    }

    //console.log(ebook);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ebook]);


  /*
    const handleCustomButtonClick = () => {
      //alert('Custom button clicked!');
      // Perform any additional actions here
      canvaRef.current.open();
  
    };
  */

  return token ? (
    <div className="container" style={{ background: "#fff", marginTop: "90px" }}>

      <div className="columns" style={{ margin: 13, background: "white", borderRadius: "3px" }}>
        <div
          className="column padd"
          style={{
            color: "black",
            padding: "40px !important",
            //border: "2px solid #FECB00",
            borderRadius: "10px",
            boxShadow: '10px 10px 60px -16px rgba(0,0,0,0.2)',

          }}
        >
          <h1>Edit Ebook</h1>
          <p style={{ color: "#909090" }}>{ebook && ebook.title}</p>

          <div className="editor-wrapper" style={{ border: "1px solid #D7D7D7", padding: "20px", marginTop: "20px", borderRadius: "5px" }}>

            <Editor
              editorState={editorState}

              toolbarClassName="editor-toolbar"
              wrapperClassName="editor-main-wrapper"
              editorClassName="editor-content"

              ref={editorRef}

              toolbar={toolbarOptions} // Apply the custom toolbar configuration
              onEditorStateChange={onEditorStateChange}
            //toolbarCustomButtons={[<CustomButton getEditorState={() => editorState} setEditorState={seteditorState} />]}
            //toolbarCustomButtons={[<CustomButton onClick={handleCustomButtonClick} />]}


            />
          </div>


          <div className="button-container" style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginTop: "20px" }}>
            <button
              style={{
                padding: "10px 15px",
                fontSize: "1.1rem",
                fontWeight: "600",
                border: 0,
                color: "#fff",
                background: "#000",
                borderRadius: "3px",
              }}

              onClick={() => {

                editEbookFn(match.params.id, getContent(), false);
              }}

            >
              Save
            </button>

            <button
              style={{
                padding: "10px 15px",
                fontSize: "1.1rem",
                fontWeight: "600",
                border: 0,
                color: "#000",
                background: "#FECB00",
                borderRadius: "3px",
              }}

              onClick={() => {
                //setclicked_ebook(row.canva_id)
                canvaRef2.current.open();
              }}
            >
              Open in Canva
            </button>
          </div>

        </div>
      </div>
      <div style={{ height: "20px" }}></div>
      <div className="columns" style={{ margin: 13 }}>
        <p style={{
          color: "black",
        }}>© 2025 EasyEbook</p>
      </div>


      <Popup
        ref={canvaRef}
        contentStyle={{/*margin:"auto 10px"*/ }}
      >

        <div style={{ padding: "30px 20px" }}>
          <div style={{ width: "300px" }}><p style={{ color: "#333", fontWeight: "600", textAlign: "center", fontSize: "1em" }}>Enter your URL
          </p>
            <div style={{ height: "15px" }}></div>

            <p style={{ color: "#333", fontWeight: "400", textAlign: "center", fontSize: "1em" }}>
              Where should we redirect the user once the click on the "Contact us" button?
            </p>
            <div style={{ height: "15px" }}></div>


            <p style={{ color: "#333", fontWeight: "400", textAlign: "center", fontSize: "1em" }}>
              <input type="text" onChange={(v) => {
                setredirect_url(v.target.value);
              }} value={redirect_url} style={{ width: "300px", height: "50px", padding: "0px 10px" }} placeholder="Enter a URL" />
            </p>

          </div>

          <div style={{ height: "15px" }}></div>

          <div className="" style={{ maxWidth: "300px", color: "white", fontSize: "0.8em", textAlign: "center", fontWeight: 600 }}> <button className="" style={{
            border: 0,
            padding: "5px 15px", lineHeight: "30px", background: "#48c774", color: "white", borderRadius: "3px"
          }} onClick={() => {


            appendHtmlToEditor(editorState, seteditorState, redirect_url, match.params.id);
            setTimeout(() => {
              const editorContent = document.querySelector('.editor-content');
              if (editorContent) {
                editorContent.scrollTop = editorContent.scrollHeight;
              }
            }, 500);
            canvaRef.current.close();
            //addCustomContent();

          }}><b>Add Contact Us</b></button></div>


        </div>
      </Popup>


      <Popup
        ref={canvaRef2}
        contentStyle={{/*margin:"auto 10px"*/ }}
      >

        <div style={{ padding: "30px 20px" }}>
          <div style={{ width: "300px" }}><p style={{ color: "#333", fontWeight: "600", textAlign: "center", fontSize: "1em" }}>Open in Canva
          </p>
            <div style={{ height: "15px" }}></div>

            {/* <p style={{ color: "#333", fontWeight: "400", textAlign: "center", fontSize: "1em" }}>
              To open this eBook in Canva, first open a new Canva document. On the left-hand panel, search for the "easyebook" app and paste the following code into the input field. Finally, click on the "Open eBook" button in Canva.
        </p>*/}
            <p style={{ color: "#333", fontWeight: "400", textAlign: "center", fontSize: "1em" }}>
              To open this ebook in Canva, first <a rel="noreferrer" href="https://www.canva.com/login/?redirect=%2Fdesign%3Fcreate%26type%3DTABQqplf4ko%26ui%3DeyJFIjp7IkE_IjoiTiIsIlMiOiJBQUdIYzZJY2xLTSIsIlQiOjN9fQ" target="_blank">click here</a>. Then, copy the ebook ID below and paste it into the Canva app.
            </p>

            <div style={{ height: "15px" }}></div>


            <p style={{ color: "#333", fontWeight: "400", textAlign: "center", fontSize: "1em" }}>
              <input type="text" disabled value={clicked_ebook} style={{ width: "300px", height: "50px", padding: "0px 10px" }} />
            </p>

          </div>

          <div style={{ height: "15px" }}></div>

          <div className="" style={{ maxWidth: "300px", color: "white", fontSize: "0.8em", textAlign: "center", fontWeight: 600 }}> <button className="" style={{
            border: 0,
            padding: "5px 15px", lineHeight: "30px", background: "#48c774", color: "white", borderRadius: "3px"
          }} onClick={() => {
            canvaRef2.current.close();

          }}><b>Close</b></button></div>


        </div>
      </Popup>


    </div >) : <Redirect to="/login" />;

}

const mapStateToProps = (state) => {
  return {

    token: state.home.token,
    ebook: state.home.ebook,
    edited: state.home.edited


  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEbookFN: (a) =>
      dispatch(getEbook(a)),

    resetCreationFn: () =>
      dispatch(resetCreation()),

    editEbookFn: (a, b, c) =>
      dispatch(editEbook(a, b, c)),


  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(Ebook);
