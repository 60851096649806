import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
//import { getSettings, saveSettings } from "../store/actions/homeActions";
//import { Redirect } from 'react-router-dom';



function Support(props) {

  //const { token, settings, getSettingsFN, saveSettingsFN } = props;

  //const [company, setcompany] = useState("");
  const [name, setname] = useState("");
  const [address, setaddress] = useState("");
  const [message, setmessage] = useState("");
  const [sent, setsent] = useState(false);




  useEffect(() => {

    //getSettingsFN();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function sendSupportRequest() {

    if (!name || !address || !message) {
      return alert("please enter all the required details")
    }
    setsent(true);
    setname("");
    setaddress("");
    setmessage("");

  }



  return (
    <div className="container" style={{ background: "#fff", marginTop: "90px" }}>

      <div className="columns" style={{ margin: 13, background: "white", borderRadius: "3px" }}>
        <div
          className="column padd"
          style={{
            color: "black",
            padding: "10px !important",
            //border: "2px solid #FECB00",
            borderRadius: "10px",
            boxShadow: '10px 10px 60px -16px rgba(0,0,0,0.2)',

          }}
        >
          <h1>Contact us</h1>
          <p style={{ color: "#909090" }}>We'd love to hear from you! Whether you have questions, feedback, or need assistance, our team is here to help.</p>

          <div style={{ height: "15px" }}></div>


          <div style={{ height: "5px" }}></div>

          <div className="field">
            <p className="control has-icons-left">
              <input
                required
                className="input"
                type="text"
                placeholder={"Enter your full name"}
                id="c"
                name="c"
                value={name}
                onChange={(v) => {
                  setname(v.target.value);
                }}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-user"></i>
              </span>
            </p>
          </div>
          <div style={{ height: "5px" }}></div>
          <div className="field">
            <p className="control has-icons-left">
              <input
                required
                className="input"
                type="text"
                placeholder={"Enter your e-mail address"}
                id="c"
                name="c"
                value={address}
                onChange={(v) => {
                  setaddress(v.target.value);
                }}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-envelope"></i>
              </span>
            </p>
          </div>
          <div style={{ height: "5px" }}></div>
          <div className="field">
            <p className="control has-icons-left">

              <textarea className="input"
                style={{ height: "90px" }}
                placeholder={"Enter your message"}
                value={message}
                onChange={(v) => {
                  setmessage(v.target.value);
                }}
              ></textarea>
              <span className="icon is-small is-left">
                <i className="fas fa-bullhorn"></i>
              </span>
            </p>
          </div>
          <div style={{ height: "10px" }}></div>


          <button
            type="submit"
            style={{
              padding: "10px 15px",
              fontSize: "1.1rem",
              fontWeight: "600",
              border: 0,
              color: "#000",
              background: "#FECB00",
              borderRadius: "3px",
            }}

            disabled={sent}
            onClick={() => {
              sendSupportRequest();

            }}
          >
            Send Support Request
          </button>
          <div style={{ height: "10px" }}></div>

          {sent ? <p>Thank you for reaching out! We have received your request and our team is currently reviewing it. We will get back to you as soon as possible.</p> : null}
        </div>
      </div>
      <div style={{ height: "20px" }}></div>
      <div className="columns" style={{ margin: 13 }}>
        <p style={{
          color: "black",
        }}>© 2025 EasyEbook</p>
      </div>

    </div >);

}

const mapStateToProps = (state) => {
  return {

    token: state.home.token,
    settings: state.home.settings,


  };
};

const mapDispatchToProps = (dispatch) => {
  return {



  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(Support);
