export default function Privacy() {



  return (<div>
    <div className="container">
      <div className="columns" style={{ marginTop: "70px" }}>
        <div className="column">
          <div className="content no-select" style={{ color: "black !important" }}>

            <h3 style={{ color: "#000" }}>Privacy Policy for easyebook</h3>
            <p style={{ color: "black !important" }}>easyebook ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you use our AI-powered eBook creation tool.</p>

            <h3 style={{ color: "#000" }}>Information We Collect</h3>
            <p style={{ color: "black !important" }}>When you create an account, we may collect your name, email address, and contact details. If you purchase a subscription, payment information such as credit card details may be collected by our payment processor. We also collect information about your interactions with easyebook, including usage statistics, accessed features, and generated content. Additionally, we gather information about the device and browser you use to access easyebook, such as IP address, operating system, and browser type.</p>


            <h3 style={{ color: "#000" }}>How We Use Your Information</h3>
            <p style={{ color: "black !important" }}>We use your information to operate, maintain, and enhance easyebook's functionality and user experience. To send you updates, newsletters, and promotional materials related to easyebook, we use your contact information; you can opt out of these communications at any time. Your information helps us respond to your inquiries and provide customer support. For analytical purposes, we may use aggregated data that does not identify individual users to analyze usage patterns and improve our services.

            </p>


            <h3 style={{ color: "#000" }}>Data Sharing and Disclosure</h3>
            <p style={{ color: "black !important" }}>We may share your information with trusted third-party service providers who assist us in operating easyebook, processing payments, or providing analytics. Your information may also be disclosed if required by law or in response to valid requests by public authorities.


            </p>

            <h3 style={{ color: "#000" }}>Data Security</h3>
            <p style={{ color: "black !important" }}>We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no data transmission over the internet or electronic storage method is completely secure, and we cannot guarantee absolute security.



            </p>



            <h3 style={{ color: "#000" }}>Your Rights</h3>
            <p style={{ color: "black !important" }}>You have the right to access and correct your personal information stored with us. You can request the deletion of your account and personal information. Additionally, you can opt out of receiving promotional communications from us by following the unsubscribe instructions included in those communications.
            </p>



            <h3 style={{ color: "#000" }}>Changes to This Privacy Policy</h3>
            <p style={{ color: "black !important" }}>
              We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the effective date.
            </p>


            <p style={{ color: "black !important" }}>
              Thank you for using easyebook. We are committed to protecting your privacy and providing a secure and enjoyable experience.               </p>



          </div>
        </div>
      </div>
      <div style={{ height: "10px" }}></div>

      <p style={{color:"black"}}>&copy; 2025 EasyEbook</p>

    </div>
    
    
    </div>);
}
