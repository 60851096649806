import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getSettingsAdmin, saveSettings_admin, getEbooks_admin } from "../store/actions/homeActions";
import { Redirect } from 'react-router-dom';

import DataTable from 'react-data-table-component';
import moment from 'moment';

import { useHistory } from 'react-router-dom';



function ManageUser(props) {

  const { token, settings, getSettingsFN, saveSettingsFN, match, ebooks, getEbooksFN, loading } = props;

  const [company, setcompany] = useState("");
  const [name, setname] = useState("");
  const [email, setemail] = useState("");

  const [address, setaddress] = useState("");
  const [country, setcountry] = useState("");
  const [credits, setcredits] = useState(0);
  const [is_admin, setis_admin] = useState(0);

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    //onChange(event.target.checked);
  };

  const history = useHistory();

  //const [totalRows, setTotalRows] = useState(0);
  //const [perPage, setPerPage] = useState(10);

/*
  const handlePageChange = page => {
    getEbooksFN(match.params.id);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    getEbooksFN(match.params.id);
  };

*/

  useEffect(() => {

    getSettingsFN(match.params.id);
    getEbooksFN(match.params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {

    if (settings) {

      setcompany(settings.company);
      setname(settings.full_name);
      setaddress(settings.address);
      setcountry(settings.country);
      setcredits(settings.credits);
      setis_admin(settings.is_admin);
      setemail(settings.email);

      if (settings.is_admin) {
        setIsChecked(true);
      }
    }



    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);



  const columns = [
    {
      name: 'Title',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{row.title}</p>),
      sortable: true,
      width: '50%',
    },
    {
      name: 'Created at',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{moment(row.dt).format('MMMM D, YYYY [at] h:mma')}</p>),
      sortable: true,
      width: '30%',
    },

    {
      name: 'Actions',
      width: '20%',
      selector: row => row.status === 1 ? (<p><button style={{
        padding: "5px 10px",
        fontSize: "1rem",
        fontWeight: "400",
        border: 0,
        color: "#fff",
        background: "#000",
        borderRadius: "3px",
      }} onClick={() => {
        history.push({
          pathname: '/admin/users/ebook/' + row.id,
        })
      }}>Edit</button></p>) : null,
    }
  ];

  return token ? (
    <div className="container" style={{ background: "#fff", marginTop: "90px" }}>

      <div className="columns" style={{ margin: 13, background: "white", borderRadius: "3px" }}>
        <div
          className="column padd"
          style={{
            color: "black",
            padding: "10px !important",
            border: "1px solid #D7D7D7",
            borderRadius: "10px",
            boxShadow: '10px 10px 60px -16px rgba(0,0,0,0.75)',
            WebkitBoxShadow: '10px 10px 60px -16px rgba(0,0,0,0.75)',
            MozBoxShadow: '10px 10px 60px -16px rgba(0,0,0,0.75)'

          }}
        >
          <h1>Manager User</h1>
          <p style={{ color: "#909090" }}>{email}</p>

          <div style={{ height: "15px" }}></div>

          <div className="field">
            <p className="control has-icons-left">
              <input
                required
                className="input"
                type="text"
                placeholder={"Company name"}
                id="c"
                name="c"
                onChange={(v) => {
                  setcompany(v.target.value);
                }}
                value={company}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-building"></i>
              </span>
            </p>
          </div>
          <div style={{ height: "5px" }}></div>
          <div className="field">
            <p className="control has-icons-left">
              <input
                required
                className="input"
                type="text"
                placeholder={"Your full name"}
                id="c"
                name="c"
                value={name}
                onChange={(v) => {
                  setname(v.target.value);
                }}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-user"></i>
              </span>
            </p>
          </div>
          <div style={{ height: "5px" }}></div>
          <div className="field">
            <p className="control has-icons-left">
              <input
                required
                className="input"
                type="text"
                placeholder={"E-mail address"}
                id="c"
                name="c"
                value={email}
                onChange={(v) => {
                  setemail(v.target.value);
                }}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-envelope"></i>
              </span>
            </p>
          </div>
          <div style={{ height: "5px" }}></div>
          <div className="field">
            <p className="control has-icons-left">
              <input
                required
                className="input"
                type="text"
                placeholder={"Address"}
                id="c"
                name="c"
                value={address}
                onChange={(v) => {
                  setaddress(v.target.value);
                }}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-envelope"></i>
              </span>
            </p>
          </div>
          <div style={{ height: "5px" }}></div>
          <div className="field">
            <p className="control has-icons-left">
              <input
                required
                className="input"
                type="text"
                placeholder={"Country"}
                id="c"
                name="c"
                value={country}
                onChange={(v) => {
                  setcountry(v.target.value);
                }}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-globe"></i>
              </span>
            </p>
          </div>
          <div style={{ height: "5px" }}></div>
          <div className="field">
            <p className="control has-icons-left">
              <input
                required
                className="input"
                type="text"
                placeholder={"Credits"}
                id="c"
                name="c"
                value={credits}
                onChange={(v) => {
                  setcredits(v.target.value);
                }}
                style={{ color: "#000000", fontSize: "1.2em" }}
              />
              <span className="icon is-small is-left">
                <i className="fas fa-star"></i>
              </span>
            </p>
          </div>
          <div style={{ height: "5px" }}></div>

          <div className="field">
            <p className="control has-icons-left">
              <label className="checkbox">
                <input type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                {"  "} Is Admin?
              </label>
            </p>
          </div>
          <div style={{ height: "10px" }}></div>



          <button
            style={{
              padding: "10px 15px",
              fontSize: "1.1rem",
              fontWeight: "600",
              border: 0,
              color: "#000",
              background: "#FECB00",
              borderRadius: "3px",
            }}

            onClick={() => {
              saveSettingsFN(company, name, address, country, credits, is_admin, match.params.id);

            }}
          >
            Save
          </button>
          <div style={{ height: "10px" }}></div>


        </div>
      </div>

      <div style={{ height: "20px" }}></div>


      <div className="columns" style={{ margin: 13, background: "white", borderRadius: "3px" }}>
        <div
          className="column"
          style={{
            color: "black",
            padding: "10px !important",
            border: "1px solid #D7D7D7",
            borderRadius: "10px",
            boxShadow: '10px 10px 60px -16px rgba(0,0,0,0.75)',
            WebkitBoxShadow: '10px 10px 60px -16px rgba(0,0,0,0.75)',
            MozBoxShadow: '10px 10px 60px -16px rgba(0,0,0,0.75)'

          }}
        >
          {/*<h2 style={{ fontWeight: "600" }}>My Ebooks</h2>*/}
          {ebooks === undefined || !ebooks || ebooks.length < 1 ? <p>The user hasn't generated any ebook yet.</p> : <DataTable
            title="Ebooks"
            columns={columns}
            data={ebooks}
            progressPending={loading}
            pagination
            paginationServer
            //paginationTotalRows={totalRows}
            //onChangeRowsPerPage={handlePerRowsChange}
            //onChangePage={handlePageChange}
          />}

        </div>
      </div>



      <div style={{ height: "20px" }}></div>
      <div className="columns" style={{ margin: 13 }}>
        <p style={{
          color: "black",
        }}>© 2025 EasyEbook</p>
      </div>

    </div >) : <Redirect to="/login" />;

}

const mapStateToProps = (state) => {
  return {

    token: state.home.token,
    settings: state.home.settings_admin,
    ebooks: state.home.ebooks_admin,
    loading: state.home.loading,


  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettingsFN: (a) =>
      dispatch(getSettingsAdmin(a)),

    saveSettingsFN: (a, b, c, d, e, f, g) =>
      dispatch(saveSettings_admin(a, b, c, d, e, f, g)),

    getEbooksFN: (user) =>
      dispatch(getEbooks_admin(user)),

  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(ManageUser);
