import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { getTXs, getPrice, getStripe, resetCreation, getBalance, cancelSubscription, subscribe, createEbook } from "../store/actions/homeActions";
import { Redirect } from 'react-router-dom';

import DataTable from 'react-data-table-component';
import moment from 'moment';
import { useHistory } from 'react-router-dom';


import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

function Billing(props) {

  const { token, txs, getTXsFN, loading, getPriceFN, price_r, match, redirect_url, getStripeFN, resetCreationFN, getBalanceFN, credits, ispaying, willcancel, cancelSubscriptionFN, subscribeFN, createEbookFn, queued, balance } = props;

  const history = useHistory();
  const canvaRef = useRef();
  const canvaRef2 = useRef();
  const payRef = useRef();
  //const [amount, setamount] = useState(1);
  const [price, setprice] = useState(1);
  const [isloading, setisloading] = useState(false);

  const [selectedValue, setSelectedValue] = useState(2);

  const handleChange = (event) => {
    console.log(event.target.value);
    setSelectedValue(parseInt(event.target.value));
  };


  //const [totalRows, setTotalRows] = useState(0);
  //const [perPage, setPerPage] = useState(10);


  const resetlocalstorage = () => {
    localStorage.removeItem('selectedLanguage');
    localStorage.removeItem('author');
    localStorage.removeItem('selectedContentType');
    localStorage.removeItem('description');
    localStorage.removeItem('script');
    localStorage.removeItem('chapters');
  }


  const getfromlocalstorage = () => {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    const author = localStorage.getItem('author');
    const selectedContentType = localStorage.getItem('selectedContentType');
    const description = localStorage.getItem('description');
    const script = localStorage.getItem('script');
    const chapters = localStorage.getItem('chapters');
    return { selectedLanguage, author, selectedContentType, description, script, chapters };
  }



  useEffect(() => {
    //alert(match.params.id);

    getBalanceFN();
    getTXsFN();
    getPriceFN();


    if (match.params.id) {
      canvaRef2.current.open();

    }



    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {

    setprice(price_r);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price_r]);


  const [waitinglink, setWaitinglink] = useState(false);



  useEffect(() => {

    if (redirect_url) {
      setWaitinglink(false);
      resetCreationFN();
      window.location.href = redirect_url;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect_url]);


  /*
    const handlePageChange = page => {
      getTXsFN(page, perPage);
    };
  
    const handlePerRowsChange = async (newPerPage, page) => {
      getTXsFN(newPerPage);
      getTXsFN(page, newPerPage);
    };
  
  */

  const columns = [

    {
      name: 'Credits',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{row.credits}</p>),
      sortable: true,
      width: '20%',
    },
    {
      name: 'Amount',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{"$" + row.amount}</p>),
      sortable: true,
      width: '10%',
    },
    {
      name: 'Method',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{row.method}</p>),
      sortable: true,
      width: '20%',
    },
    {
      name: 'Created at',
      selector: row => (<p style={{ fontSize: "1.2em" }}>{moment(row.dt).format('MMMM D, YYYY [at] h:mma')}</p>),
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => parseInt(row.status) === 0 ? (<span style={{ color: "#000", fontSize: "1.2em" }}>Failed</span>) : (<span style={{ color: "#79CA27", fontSize: "1.2em" }}>Paid</span>),
      sortable: true,
    },/*
    {
      name: 'Invoice',
      selector: row => (<p><button style={{
        padding: "5px 10px",
        fontSize: "1.2em",
        fontWeight: "400",
        border: 0,
        color: "#000",
        background: "#FECB00",
        borderRadius: "3px",
      }} onClick={() => {
        window.open(row.receipt);

      }}>Get Invoice</button></p>),
      sortable: true,
    }*/
  ];

  const [showCredits, setShowCredits] = useState(false);


  useEffect(() => {
    if (ispaying) {
      setShowCredits(true);
    }
  }, [ispaying]);


  // Add a new state to track if we've already shown the success message
  const [hasShownSuccess, setHasShownSuccess] = useState(false);

  // Add a new state to track the creation process
  const [creationState, setCreationState] = useState('initial'); // 'initial', 'creating', 'success'
  const [creationloading, setcreationloading] = useState(false);

  /*
  // Add this near the top with other state declarations
  const [timeLeft, setTimeLeft] = useState({
    hours: 4,
    minutes: 52,
    seconds: 59
  });

  // Add this useEffect for the countdown timer
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime.hours === 0 && prevTime.minutes === 0 && prevTime.seconds === 0) {
          clearInterval(timer);
          return prevTime;
        }
        
        let newSeconds = prevTime.seconds - 1;
        let newMinutes = prevTime.minutes;
        let newHours = prevTime.hours;
        
        if (newSeconds < 0) {
          newSeconds = 59;
          newMinutes -= 1;
        }
        if (newMinutes < 0) {
          newMinutes = 59;
          newHours -= 1;
        }
        
        return {
          hours: newHours,
          minutes: newMinutes,
          seconds: newSeconds
        };
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);
*/
  // Modify create_ebook_or_not to be simpler
  const create_ebook_or_not = (() => {
    console.log('Checking create_ebook_or_not conditions:', {
      hasShownSuccess,
      balance,
      matchParamsId: match.params.id,
      creationState
    });
    
    if (hasShownSuccess || creationState !== 'initial') {
      console.log('Skipping creation - already in progress or completed');
      return false;
    }
    
    const storage = getfromlocalstorage();
    const hasRequiredData = storage.selectedLanguage &&
      storage.author &&
      storage.selectedContentType &&
      storage.description;
    
    console.log('Storage data check:', {
      hasRequiredData,
      storageData: storage
    });
    
    return hasRequiredData && match.params.id === "ok" && balance > 0;
  })();

  // Modify balance update effect
  useEffect(() => {
    if (match.params.id === "ok") {
      console.log('Payment success detected, waiting for balance update...', {
        currentBalance: balance,
        creationState,
        timestamp: new Date().toISOString()
      });
      
      setTimeout(() => {
        console.log('Checking conditions after delay:', {
          create_ebook_or_not,
          balance,
          creationState,
          timestamp: new Date().toISOString()
        });
        
        if (create_ebook_or_not && creationState === 'initial') {
          console.log('Starting ebook creation process');
          setCreationState('creating');
          setcreationloading(true);
          const storage = getfromlocalstorage();
          createEbookFn(
            storage.selectedLanguage,
            storage.author,
            storage.selectedContentType,
            storage.description,
            storage.script,
            storage.chapters
          );
        }
      }, 3000);
    }
  }, [match.params.id, balance]);

  // Modify queued effect
  useEffect(() => {
    console.log('Queued status changed:', {
      queued,
      hasShownSuccess,
      creationState,
      creationloading
    });
    
    if (queued) {
      console.log('Creation queued, updating states');
      setCreationState('success');
      setHasShownSuccess(true);
      resetlocalstorage();
      resetCreationFN();
      setcreationloading(false);
    }
  }, [queued]);

  // Add this near the top with other state declarations
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Add this useEffect for window resize handling
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return token ? (
    <div className="container" style={{ background: "#fff", marginTop: "90px" }}>




      <div className="columns" style={{ margin: 13, background: "white", borderRadius: "3px" }}>
        <div
          className="column"
          style={{
            color: "black",
            padding: "10px !important",
            //border: "2px solid #FECB00",
            borderRadius: "10px",
            boxShadow: '10px 10px 60px -16px rgba(0,0,0,0.2)',

          }}
        >

          <div className="flex-container">


            <button className="right-button" style={{
              padding: "10px 25px",
              fontSize: "1.2em",
              fontWeight: "600",
              border: 0,
              color: ispaying ? "#fff" : "#000",
              background: ispaying ? "#000" : "#FECB00",
              borderRadius: "3px",
            }} onClick={() => {
              if (ispaying) {
                cancelSubscriptionFN();

              } else {
                setShowCredits(false);
                payRef.current.open();
              }

            }}>
              {ispaying ? "Cancel Subscription" : "Subscribe"}
            </button>



            <button className="right-button" style={{
              padding: "10px 25px",
              fontSize: "1.2em",
              fontWeight: "600",
              border: 0,
              color: "#000",
              background: "#FECB00",
              borderRadius: "3px",
            }} onClick={() => {
              if (canvaRef.current) {
                setShowCredits(true);
                payRef.current.open();

              }

            }}>
              Buy more credits
            </button>
          </div>




          {/*<h2 style={{ fontWeight: "600" }}>My Ebooks</h2>*/}
          {txs === undefined || !txs || txs.length < 1 ? <p style={{ padding: "0px 20px 20px 20px" }}>You haven't made any payment yet.</p> : <DataTable
            title="My Transactions"
            columns={columns}
            data={txs}
            progressPending={loading}
            pagination={true}

            paginationRowsPerPageOptions={[10, 20, 30]}
            paginationPerPage={10} // Set the default number of rows per page
            paginationTotalRows={txs.length} // Total number of rows in your data
            paginationDefaultPage={1}


          //paginationTotalRows={totalRows}
          //onChangeRowsPerPage={handlePerRowsChange}
          // onChangePage={handlePageChange}
          />}

        </div>
      </div>
      <div style={{ height: "20px" }}></div>
      <div className="columns" style={{ margin: 13 }}>
        <p style={{
          color: "black",
        }}>© 2025 EasyEbook</p>
      </div>



      <Popup
        ref={canvaRef2}
        contentStyle={{/*margin:"auto 10px"*/ }}
      >

        <div style={{ padding: "30px 20px" }}>
          <div style={{ width: "300px" }}>


            {match.params.id === "ok" && (creationState === 'creating' || creationState === 'success') && (
              <div id="step5" style={{ margin: "0 auto", textAlign: "center" }}>
                {creationState === 'creating' ? (
                  <>
                    <h2 style={{ fontWeight: "600", fontSize: "1.1em", color: "#000" }}>Please wait...</h2>
                    <p style={{ color: "#909090", marginTop: "10px" }}>
                      <i className="fa fa-spinner fa-spin" style={{ fontSize: "2.5em" }}></i>
                    </p>
                  </>
                ) : (
                  <>
                    <h2 style={{ fontWeight: "600", fontSize: "1.1em", color: "#000" }}>
                      Congratulations! <br /> Your ebook is being created!
                    </h2>
                    <p style={{ color: "#909090", marginTop: "10px" }}>
                      Click the button below to check the status of your new ebook.
                    </p>
                    <div style={{ height: "20px" }}></div>

                    <div className="wrapperan">
                      <svg className="animated-check" viewBox="0 0 24 24">
                        <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" /> </svg>
                      </div>

                    <div style={{ height: "20px" }}></div>

                    <div className="button-container" style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <button
                        style={{
                          padding: "10px 15px",
                          fontSize: "1.1rem",
                          fontWeight: "600",
                          border: 0,
                          color: "#000",
                          background: "#FECB00",
                          borderRadius: "3px",
                          margin: "0 auto"
                        }}
                        onClick={() => {
                          resetCreationFN();
                          history.push({
                            pathname: '/ebooks',
                          })
                        }
                        }
                      >
                        Go to your Ebooks
                      </button>
                    </div>

                  </>
                )}
              </div>
            )}

            {match.params.id === "ok" && creationState === 'initial' && !hasShownSuccess && (
              <div id="step5" style={{ margin: "0 auto", textAlign: "center" }}>
                <h2 style={{ fontWeight: "600", fontSize: "1.1em", color: "#000" }}>
                  Payment Successful!
                </h2>
                <p style={{ color: "#909090", marginTop: "10px" }}>Your credits have been added to your account.</p>
                <div style={{ height: "20px" }}></div>

                <div className="wrapperan">
                  <svg className="animated-check" viewBox="0 0 24 24">
                    <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" /> </svg>
                </div>

                <div style={{ height: "20px" }}></div>

                <div className="button-container" style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <button
                    style={{
                      padding: "10px 15px",
                      fontSize: "1.1rem",
                      fontWeight: "600",
                      border: 0,
                      color: "#000",
                      background: "#FECB00",
                      borderRadius: "3px",
                      margin: "0 auto"
                    }}
                    onClick={() => {
                      resetCreationFN();
                      history.push({
                        pathname: '/create',
                      })
                    }
                    }
                  >
                    Create an ebook
                  </button>
                </div>

              </div>
            )}

            {match.params.id !== "ok" && <>

              <p style={{ color: "#333", textAlign: "center", fontSize: "1.2em" }}>There was an issue with your payment. <br /><br /> Please try again.
              </p>

              <div className="" style={{ maxWidth: "300px", color: "white", fontSize: "0.8em", textAlign: "center", fontWeight: 600 }}> <button className="" style={{
                border: 0,
                padding: "5px 15px", lineHeight: "30px", background: "#FECB00", color: "#000", borderRadius: "3px", marginTop: "20px"
              }} onClick={() => {



                canvaRef2.current.close();

              }}><b>Close</b></button>






              </div></>}






          </div>


        </div>
      </Popup >


      <Popup
        ref={canvaRef}
        contentStyle={{/*margin:"auto 10px"*/ }}
      >

        <div style={{ padding: "30px 20px" }}>
          <div style={{ width: "600px" }}><p style={{ color: "#333", fontWeight: "600", textAlign: "center", fontSize: "1.2em" }}>Buy Credits
          </p>
            <div style={{ height: "15px" }}></div>
            <div className="billingt">
              <div className="billingt2">
                <input type="radio" id="control_01" name="select"
                  value="2"
                  checked={selectedValue === 2}
                  onChange={handleChange}
                  className="radiode"

                />
                <label htmlFor="control_01" className="labelde">
                  <h2 style={{ fontWeight: 600, fontSize: "1.2em" }}>2 Credits - $9.99</h2>
                  <p>Create 2 eBooks</p>
                </label>
              </div>
              <div className="billingt2">
                <input type="radio" id="control_02" name="select" value="10"
                  checked={selectedValue === 10}
                  onChange={handleChange}
                  className="radiode"
                />
                <label htmlFor="control_02" className="labelde">
                  <h2 style={{ fontWeight: 600, fontSize: "1.2em" }}>10 Credits - $29.99</h2>
                  <p>Create 10 eBooks</p>
                </label>
              </div>
              <div className="billingt2">
                <input type="radio" id="control_03" name="select" value="20"
                  checked={selectedValue === 20}
                  onChange={handleChange}
                  className="radiode"
                />
                <label htmlFor="control_03" className="labelde">
                  <h2 style={{ fontWeight: 600, fontSize: "1.2em" }}>20 Credits - $59.99</h2>
                  <p>Create 20 eBooks</p>
                </label>
              </div>
            </div>



            {/*<p style={{ color: "#333", fontWeight: "400", textAlign: "center", fontSize: "1em" }}>
              Generating one eBook requires 1 credit,
              1 Credit is ${price}
        </p>
            <div style={{ height: "15px" }}></div>


            <p style={{ color: "#333", fontWeight: "400", textAlign: "center", fontSize: "1em" }}>
              <input type="number" min="1" onChange={(v) => {
                setamount(v.target.value);
              }} value={amount} style={{ width: "300px", height: "50px", padding: "0px 10px" }} placeholder="Enter an amount of credits" />
            </p>
*/}
          </div>

          <div style={{ height: "15px" }}></div>

          <div className="" style={{ color: "white", fontSize: "0.8em", textAlign: "center", fontWeight: 600 }}>


            {/*<button disabled={isloading} className="" style={{
            border: 0,
            padding: "5px 15px", lineHeight: "30px", background: "#48c774", color: "white", borderRadius: "3px"
          }} onClick={() => {



            //canvaRef.current.close();
            //go2checkout();
            setisloading(true);
            getStripeFN(price, amount);

          }}><b>Pay ${(price * amount).toFixed(2)}</b></button>*/}

            <button disabled={isloading} className="" style={{
              border: 0,
              fontSize: "1.5em",
              padding: "10px 35px", lineHeight: "30px", background: "hsla(150, 75%, 45%, 1)", color: "white", borderRadius: "3px"
            }} onClick={() => {



              //canvaRef.current.close();
              //go2checkout();
              setisloading(true);
              getStripeFN(price, selectedValue);

            }}><b>Go to Checkout</b></button>


            <div style={{ height: "15px" }}></div>

            <p style={{ textDecoration: "underline", color: "#000" }} onClick={() => {

              canvaRef.current.close();

            }}>No, thanks!</p>
          </div>


        </div>
      </Popup >






      <Popup
        ref={payRef}
        contentStyle={{
          padding: "30px 20px",
          width: windowWidth <= 768 ? "100%" : "1160px",
          margin: "auto",
          background: "#000",
          borderRadius: "5px",
          border: "0px solid #fff",
          boxSizing: "border-box",
          maxHeight: windowWidth <= 768 ? "100vh" : "auto",
          overflowY: windowWidth <= 768 ? "auto" : "visible"
        }}
      >
        <div style={{
          width: windowWidth <= 768 ? "100%" : "800px",
          margin: "0 auto",
          padding: windowWidth <= 768 ? "0 15px" : "0"
        }}>
          <h2 style={{
            textAlign: "center",
            fontSize: "2em",
            marginBottom: "20px",
            color: "#fff"
          }}>You don't have enough credits</h2>

          <div style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "30px",
            background: "#f5f5f5",
            padding: "5px",
            borderRadius: "25px",
            width: windowWidth <= 768 ? "100%" : "300px",
            margin: showCredits ? "0 auto 20px" : "0 auto 30px"
          }}>
            <button
              onClick={() => { if (!ispaying) setShowCredits(false) }}
              style={{
                padding: "8px 20px",
                border: "none",
                borderRadius: "20px",
                background: !showCredits ? "#FECB00" : "transparent",
                color: !showCredits ? "#000" : "#666",
                cursor: "pointer",
                fontWeight: "600",
                flex: 1
              }}
            >
              Plans
            </button>
            <button
              onClick={() => setShowCredits(true)}
              style={{
                padding: "8px 20px",
                border: "none",
                borderRadius: "20px",
                background: showCredits ? "#FECB00" : "transparent",
                color: showCredits ? "#000" : "#666",
                cursor: "pointer",
                fontWeight: "600",
                flex: 1
              }}
            >
              Credits
            </button>
          </div>

          <div style={{
            display: "flex",
            justifyContent: "center",
            width: "100%"
          }}>
            {!showCredits ? (
              // Plans view
              <div style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                gap: windowWidth <= 768 ? "20px" : "30px",
                flexDirection: windowWidth <= 768 ? "column" : "row"
              }}>
                <div style={{
                  background: "#fff",
                  borderRadius: "10px",
                  padding: "24px",
                  width: windowWidth <= 768 ? "auto" : "340px",
                  boxShadow: "0px 0px 15px rgba(0,0,0,0.1)",
                  position: "relative"
                }}>
                  <div style={{
                    background: "#FECB00",
                    padding: "8px 16px",
                    borderRadius: "20px",
                    width: "fit-content",
                    position: "absolute",
                    top: "-12px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    zIndex: 1
                  }}>
                    <span style={{ color: "#000" }}>Most Popular</span>
                  </div>

                  <div style={{ height: "25px" }}></div>

                  <h3 style={{ color: "#000", fontSize: "1.8em", marginBottom: "8px" }}>EasyEbook Pro</h3>
                  <p style={{ color: "#666", marginBottom: "16px" }}>20 Credits/Month</p>
                  <h4 style={{ color: "#000", fontSize: "2em", marginBottom: "24px" }}>$20.00/Month</h4>

                  <button style={{
                    width: "100%",
                    padding: "12px",
                    background: "#FECB00",
                    border: "none",
                    borderRadius: "8px",
                    color: "#000",
                    fontSize: "1.1em",
                    cursor: "pointer",
                    marginBottom: "24px",
                    fontWeight: "600",
                    disabled: waitinglink
                  }} onClick={() => {
                    setWaitinglink(true);
                    subscribeFN();
                  }}>
                    {waitinglink ? <i className="fa fa-spinner fa-spin"></i> : "Subscribe"}
                  </button>

                  <ul style={{ color: "#000", listStyle: "none", padding: 0 }}>
                    {[
                      "20 Credits",
                      "Premium Version (GPT-o1)",
                      "Export in Canva",
                      "Multiple languages",
                      "Up to 18 chapters",

                    ].map((feature, index) => (
                      <li key={index} style={{ marginBottom: "12px" }}>
                        <span style={{ color: "#FECB00", marginRight: "8px" }}>✓</span>
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ) : (
              // Credits view
              <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                gap: windowWidth <= 768 ? "20px" : "30px"
              }}>
                <div style={{
                  color: "#fff",
                  textAlign: "center",
                }}>
           
                  <p style={{ 
                    fontSize: "1.1em",
                    color: "#fff" 
                  }}>
                    <b style={{ color: "#FECB00", fontSize: "1.2em" }}>🔥 Limited Time Offer</b><br />
                   {/* Offer ends in: {String(timeLeft.hours).padStart(2, '0')}:{String(timeLeft.minutes).padStart(2, '0')}:{String(timeLeft.seconds).padStart(2, '0')}} */}
                  </p>
                </div>
                
                <div style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  gap: windowWidth <= 768 ? "20px" : "30px",
                  flexDirection: windowWidth <= 768 ? "column" : "row",
                  flexWrap: windowWidth <= 768 ? "nowrap" : "wrap"
                }}>
                  {[
                    { name: "Basic", credits: "10 CREDITS", price: "$19.99", isOff: true, originalPrice: "$39.90", credits_no: 10 },
                    { name: "Essential", credits: "20 CREDITS", price: "$59.90", isOff: false, credits_no: 20 }
                  ].map((plan, index) => (
                    <div key={index} style={{
                      background: "#fff",
                      borderRadius: "10px",
                      padding: "24px",
                      width: windowWidth <= 768 ? "auto" : "340px",
                      boxShadow: "0px 0px 15px rgba(0,0,0,0.1)",
                      position: "relative"
                    }}>
                      {plan.isOff && (
                        <div style={{
                          background: "#FECB00",
                          padding: "8px 16px",
                          borderRadius: "20px",
                          width: "fit-content",
                          position: "absolute",
                          top: "-12px",
                          left: "50%",
                          transform: "translateX(-50%)",
                          zIndex: 1
                        }}>
                          <span style={{ color: "#000", fontWeight: 600 }}>50% OFF</span>
                        </div>
                      )}

                      <h3 style={{ color: "#000", fontSize: "1.8em", marginBottom: "8px" }}>{plan.name}</h3>
                      <p style={{ color: "#666", marginBottom: "16px" }}>{plan.credits}</p>
                      <div style={{ marginBottom: "24px" }}>
                        {plan.isOff && (
                          <span style={{
                            color: "#666",
                            textDecoration: "line-through",
                            marginRight: "8px"
                          }}>{plan.originalPrice}</span>
                        )}
                        <span style={{ color: "#000", fontSize: "2em" }}>{plan.price}</span>
                      </div>

                      <button style={{
                        width: "100%",
                        padding: "12px",
                        background: "#FECB00",
                        border: "none",
                        borderRadius: "8px",
                        color: "#000",
                        fontSize: "1.1em",
                        cursor: "pointer",
                        marginBottom: "24px",
                        fontWeight: "600",
                        disabled: waitinglink
                      }} onClick={() => {
                        setWaitinglink(true);
                        getStripeFN(null, plan.credits_no);
                      }}>
                        {waitinglink ? <i className="fa fa-spinner fa-spin"></i> : "Buy credits"}
                      </button>

                      <ul style={{ color: "#000", listStyle: "none", padding: 0 }}>
                        {[
                          "Up to 18 chapters",
                          "Multiple languages",
                          "Edit Ebook",
                          "Export to Canva",
                          "No watermark"
                        ].map((feature, idx) => (
                          <li key={idx} style={{ marginBottom: "12px" }}>
                            <span style={{ color: "#FECB00", marginRight: "8px" }}>✓</span>
                            {feature}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </Popup>



    </div >) : <Redirect to="/login" />;

}

const mapStateToProps = (state) => {
  return {

    token: state.home.token,
    txs: state.home.txs,
    total: state.home.total,
    page: state.home.page,
    limit: state.home.limit,
    loading: state.home.loading,
    price_r: state.home.price,
    redirect_url: state.home.redirect_url,
    credits: state.home.credits,
    ispaying: state.home.ispaying,
    willcancel: state.home.willcancel,
    queued: state.home.queued,
    balance: state.home.balance,


  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTXsFN: (a, b) =>
      dispatch(getTXs(a, b)),
    getPriceFN: () =>
      dispatch(getPrice()),
    getStripeFN: (a, b) =>
      dispatch(getStripe(a, b)),
    resetCreationFN: () =>
      dispatch(resetCreation()),
    getBalanceFN: () =>
      dispatch(getBalance()),
    cancelSubscriptionFN: () =>
      dispatch(cancelSubscription()),
    subscribeFN: () =>
      dispatch(subscribe()),
    createEbookFn: (selectedLanguage, author, selectedContentType, description, script, chapters) =>
      dispatch(createEbook(selectedLanguage, author, selectedContentType, description, script, chapters)),

  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(Billing);

