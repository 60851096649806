import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import { resetErrors, sendStep, resetCreation, createEbook, getBalance, subscribe, getStripe } from "../store/actions/homeActions";

import { useHistory, useLocation } from 'react-router-dom';

import Select from 'react-select';
import FlagIcon from 'react-flagkit'; // Import FlagIcon

function CreateEbook(props) {



  const location = useLocation();
  const { daje, selectedLanguage2, author2, selectedContentType2, description2, script2, chapters2 } = location.state || {};


  const { token, error_in_creation, sendStepFN, sub_niches, resetErrorsFn, titles, preview, queued, resetCreationFn, createEbookFn, getBalanceFn, balance, ispaying, willcancel, redirect_url, subscribeFn, getStripeFn } = props;
  const [errore, seterrore] = useState("");
  const [niche, setniche] = useState("");
  const [audience, setaudience] = useState("");

  const canvaRef = useRef();
  const payRef = useRef();


  const [step, setstep] = useState(0);

  const [subniches, setsubniches] = useState([]);
  const [titlesarr, settitles] = useState([]);


  const [selectedSubNiche, setSelectedSubNiche] = useState('');
  const [selectedTitle, setSelectedTitle] = useState('');
  const [previewstate, setpreview] = useState([]);

  const [isloading, setisloading] = useState(false);


  const history = useHistory();


  const localsetolocalstorage = (selectedLanguage, author, selectedContentType, description, script, chapters) => {
    localStorage.setItem('selectedLanguage', selectedLanguage.value);
    localStorage.setItem('author', author);
    localStorage.setItem('selectedContentType', selectedContentType.value);
    localStorage.setItem('description', description);
    localStorage.setItem('script', script);
    localStorage.setItem('chapters', chapters);
  }


  useEffect(() => {
    if (redirect_url) {
      setWaitinglink(false);
      resetCreationFn();
      localsetolocalstorage(selectedLanguage2 || selectedLanguage, author2 || author, selectedContentType2 || selectedContentType, description2 || description, script2 || script, chapters2 || chapters);
      window.location.href = redirect_url;
    }
  }, [redirect_url]);


  useEffect(() => {





    seterrore(error_in_creation);
    setisloading(false);

    setTimeout(() => {
      resetErrorsFn();
    }, 8000);


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error_in_creation]);


  useEffect(() => {

    if (niche) {

      setstep(1);

    }
    setsubniches(sub_niches);
    setisloading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sub_niches]);


  useEffect(() => {

    if (audience) {

      setstep(3);

    }
    settitles(titles);
    setisloading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titles]);


  useEffect(() => {

    if (selectedTitle !== '') {

      setstep(4);

    }

    setpreview(preview);
    setisloading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preview]);


  useEffect(() => {

    if (queued) {

      setstep(3);

    }

    setisloading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queued]);



  function next(i) {
    //setisloading(true);
    seterrore("");

    if (i === 0) {
      //step 1
      if (author.length < 1) {

        seterrore("Please enter an author's name to continue.");


      }
      else {
        //setsubniches([]);
        // sendStepFN(0, niche, null);
        setstep(1);
      }
    }


    else if (i === 1) {
      //step 2
      if (description === '') {
        seterrore("Please enter a description for your ebook.");


      }
      else {
        //setsubniches([]);
        //sendStepFN(0, niche);
        //setstep(2);
        //setisloading(false);
        setstep(2);

      }
    }


    else if (i === 2) {
      //step 3
      setisloading(true);

      if (!token) {
        canvaRef.current.open();
        setisloading(false);
        /*
        sessionStorage.setItem('not_logged', true);
        sessionStorage.setItem('step', 1);
        sessionStorage.setItem('niche', niche);
        sessionStorage.setItem('selectedSubNiche', selectedSubNiche);
        sessionStorage.setItem('selectedTitle', selectedTitle);
        sessionStorage.setItem('audience', audience);
        sessionStorage.setItem('previewstate', JSON.stringify(previewstate));
        */
        return false;

      }


      else {
        //check if has credits
        if (balance < credits) {
          setisloading(false);
          payRef.current.open();
          //seterrore("You don't have enough credits to generate this ebook. Please buy more credits.");
          return false;
        }

        createEbookFn(selectedLanguage.value, author, selectedContentType.value, description, script, chapters);

      }

      //sendStepFN(3, niche, selectedSubNiche, selectedTitle, audience, previewstate);


    }




  }


  useEffect(() => {
    if (subniches && subniches.length > 0) {
      setSelectedSubNiche(subniches[0].sub_niche);
    }
  }, [subniches]);


  useEffect(() => {
    if (titlesarr && titlesarr.length > 0) {
      setSelectedTitle(titlesarr[0].title);
    }
  }, [titlesarr]);


  useEffect(() => {

    // payRef.current.open();


    if (daje) {
      console.log("arieccoti!");
      setisloading(true);

      //niche_2, selectedSubNiche_2, selectedTitle_2, audience_2, previewstate_2

      //sendStepFN(3, niche_2, selectedSubNiche_2, selectedTitle_2, audience_2, previewstate_2);

      if (balance < credits) {
        setisloading(false);
        payRef.current.open();
        //seterrore("You don't have enough credits to generate this ebook. Please buy more credits.");
        return false;
      }

      createEbookFn(selectedLanguage2, author2, selectedContentType2, description2, script2, chapters2);


    }




    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    //  payRef.current.open();


    if (token) {
      getBalanceFn();
    }
  }, []);

  useEffect(() => {

    console.log(`current step is ${step}`);

  }, [step]);

  const languages = [
    { value: 'en-US', label: 'English (United States)', countryCode: 'US' },
    { value: 'en-GB', label: 'English (United Kingdom)', countryCode: 'GB' },
    { value: 'en-CA', label: 'English (Canada)', countryCode: 'CA' },
    { value: 'en-AU', label: 'English (Australia)', countryCode: 'AU' },
    { value: 'en-IN', label: 'English (India)', countryCode: 'IN' },
    { value: 'en-NZ', label: 'English (New Zealand)', countryCode: 'NZ' },
    { value: 'en-ZA', label: 'English (South Africa)', countryCode: 'ZA' },
    { value: 'de', label: 'German (Germany)', countryCode: 'DE' },
    { value: 'ar-SA', label: 'Arabic (Saudi Arabia) [Beta]', countryCode: 'SA' },
    { value: 'ar-EG', label: 'Arabic (Egypt) [Beta]', countryCode: 'EG' },
    { value: 'ar-AE', label: 'Arabic (United Arab Emirates) [Beta]', countryCode: 'AE' },
    { value: 'bn', label: 'Bengali (Bangladesh)', countryCode: 'BD' },
    { value: 'bs', label: 'Bosnian', countryCode: 'BA' },
    { value: 'ca', label: 'Catalan', countryCode: 'ES' },
    { value: 'hr', label: 'Croatian (Croatia)', countryCode: 'HR' },
    { value: 'da', label: 'Danish', countryCode: 'DK' },
    { value: 'sk', label: 'Slovak', countryCode: 'SK' },
    { value: 'sl', label: 'Slovenian', countryCode: 'SI' },
    { value: 'es-AR', label: 'Spanish (Argentina)', countryCode: 'AR' },
    { value: 'es-CO', label: 'Spanish (Colombia)', countryCode: 'CO' },
    { value: 'es-ES', label: 'Spanish (Spain)', countryCode: 'ES' },
    { value: 'es-MX', label: 'Spanish (Mexico)', countryCode: 'MX' },
    { value: 'et', label: 'Estonian', countryCode: 'EE' },
    { value: 'fi', label: 'Finnish', countryCode: 'FI' },
    { value: 'fr-BE', label: 'French (Belgium)', countryCode: 'BE' },
    { value: 'fr-CA', label: 'French (Canada)', countryCode: 'CA' },
    { value: 'fr-FR', label: 'French (France)', countryCode: 'FR' },
    { value: 'fr-CH', label: 'French (Switzerland)', countryCode: 'CH' },
    { value: 'gl', label: 'Galician (Spain)', countryCode: 'ES' },
    { value: 'cy', label: 'Welsh', countryCode: 'GB' },
    { value: 'el', label: 'Greek', countryCode: 'GR' },
    { value: 'he', label: 'Hebrew (Israel)', countryCode: 'IL' },
    { value: 'hi', label: 'Hindi (India)', countryCode: 'IN' },
    { value: 'nl-NL', label: 'Dutch (Netherlands)', countryCode: 'NL' },
    { value: 'hu', label: 'Hungarian', countryCode: 'HU' },
    { value: 'id', label: 'Indonesian (Indonesia)', countryCode: 'ID' },
    { value: 'is', label: 'Icelandic', countryCode: 'IS' },
    { value: 'it', label: 'Italian (Italy)', countryCode: 'IT' },
    { value: 'jv', label: 'Javanese (Indonesia)', countryCode: 'ID' },
    { value: 'lv', label: 'Latvian', countryCode: 'LV' },
    { value: 'lt', label: 'Lithuanian', countryCode: 'LT' },
    { value: 'ms', label: 'Malay (Malaysia)', countryCode: 'MY' },
    { value: 'nl-BE', label: 'Dutch (Belgium)', countryCode: 'BE' },
    { value: 'no', label: 'Norwegian', countryCode: 'NO' },
    { value: 'pl', label: 'Polish', countryCode: 'PL' },
    { value: 'pt-BR', label: 'Portuguese (Brazil)', countryCode: 'BR' },
    { value: 'pt-PT', label: 'Portuguese (Portugal)', countryCode: 'PT' },
    { value: 'ro', label: 'Romanian', countryCode: 'RO' },
    { value: 'ru', label: 'Russian [Beta]', countryCode: 'RU' },
    { value: 'sr', label: 'Serbo-Croatian', countryCode: 'RS' },
    { value: 'sv', label: 'Swedish', countryCode: 'SE' },
    { value: 'tl', label: 'Tagalog (Philippines)', countryCode: 'PH' },
    { value: 'th', label: 'Thai', countryCode: 'TH' },
    { value: 'ta', label: 'Tamil (India)', countryCode: 'IN' },
    { value: 'cs', label: 'Czech', countryCode: 'CZ' },
    { value: 'tr', label: 'Turkish', countryCode: 'TR' },
    { value: 'uk', label: 'Ukrainian (Ukraine) [Beta]', countryCode: 'UA' },
    { value: 'ur', label: 'Urdu (Pakistan)', countryCode: 'PK' },
    { value: 'vi', label: 'Vietnamese', countryCode: 'VN' }
  ];

  const [selectedLanguage, setSelectedLanguage] = useState({ value: 'en-US', label: 'English (United States)', countryCode: 'US' });
  const [author, setauthor] = useState('');
  const [selectedContentType, setSelectedContentType] = useState({ value: 'Informational', label: 'Informational' });
  const [description, setDescription] = useState('');
  const [showScript, setShowScript] = useState(false);
  const [script, setScript] = useState('');

  const [chapters, setChapters] = useState(6);
  const [credits, setCredits] = useState(1);

  // Add this function to calculate credits based on chapters
  const calculateCredits = (chapterCount) => {
    if (chapterCount <= 6) return 1;
    if (chapterCount <= 12) return 2;
    return 3;
  };

  //console.log(selectedLanguage);
  const [showCredits, setShowCredits] = useState(true);


  useEffect(() => {
    if (ispaying) {
      setShowCredits(true);
    }
  }, [ispaying]);

  const [waitinglink, setWaitinglink] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Add this useEffect for window resize handling
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="container" style={{ background: "#fff", marginTop: "90px" }}>

      <div className="columns" style={{ margin: 13, background: "white", borderRadius: "3px" }}>
        <div
          className="column padd"
          style={{
            color: "black",
            padding: "20px 110px !important",
            //border: "2px solid #FECB00",
            borderRadius: "10px",
            boxShadow: '10px 10px 60px -16px rgba(0,0,0,0.20)',


          }}
        >
          {step !== 3 ? <h1>Create New Ebook</h1> : null}

          <p style={{ color: "#909090", marginBottom: "16px" }}>Generate your Ebook in just 3 steps</p>


          <div className="custom-loader" style={{ display: isloading ? 'block' : 'none' }}></div>
          <div id="steps" style={{ display: !isloading ? 'block' : 'none' }}>
            <div id="step1" style={{ display: step === 0 ? 'block' : 'none' }}>
              <div style={{ height: "15px" }}></div>
              <p style={{ color: "#909090" }}>What will be the language of your Ebook?</p>

              <div style={{ height: "10px" }}></div>

              <Select
                options={languages}
                placeholder="Select a language"
                value={selectedLanguage}
                onChange={setSelectedLanguage}
                formatOptionLabel={({ label, countryCode }) => (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FlagIcon country={countryCode} size={16} style={{ marginRight: '8px' }} />
                    <span style={{ fontSize: '1.1em', color: '#333' }}>{label}</span>
                  </div>
                )}
                styles={{
                  control: (base) => ({
                    ...base,
                    padding: '4px',
                    borderRadius: '4px',
                    border: '1px solid #ddd',
                    boxShadow: 'none',
                    '&:hover': {
                      border: '1px solid #FECB00'
                    }
                  }),
                  option: (base, state) => ({
                    ...base,
                    padding: '8px 12px',
                    backgroundColor: state.isSelected ? '#FECB00' : 'white',
                    color: state.isSelected ? 'black' : '#333',
                    '&:hover': {
                      backgroundColor: '#fff5cc'
                    }
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 9999
                  })
                }}
              />
              <div style={{ height: "20px" }}></div>

              <p style={{ color: "#909090" }}>What is the author's name?</p>
              <div style={{ height: "10px" }}></div>


              <div className="field">
                <p className="control has-icons-left">
                  <input
                    required
                    className="input"
                    type="text"
                    placeholder={"Your name"}
                    id="niche"
                    name="niche"
                    onChange={(v) => {
                      setauthor(v.target.value);
                    }}
                    style={{ color: "#000000", fontSize: "1.2em" }}
                  />
                  <span className="icon is-small is-left" style={{ zIndex: 1 }}>
                    <i className="fas fa-user"></i>
                  </span>
                </p>
              </div>
              <div style={{ height: "15px" }}></div>

              {errore ? <p style={{ color: "red", marginBottom: "15px" }}>{errore}</p> : null}


              <div className="field">
                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#000",
                    background: "#FECB00",
                    borderRadius: "3px",
                  }}
                  onClick={() => next(0)}
                >
                  Next
                </button>
              </div>

            </div>

            <div id="step2" style={{ display: step === 1 ? 'block' : 'none' }}>
              <div style={{ height: "20px" }}></div>

              <p style={{ color: "#909090" }}>Content type:</p>
              <div style={{ height: "10px" }}></div>


              <Select
                options={[
                  { value: 'Informational', label: 'Informational' },
                  { value: 'Educational', label: 'Educational' },
                  { value: 'Motivational', label: 'Motivational' },
                  { value: 'Self-Help', label: 'Self-Help' },
                  { value: 'Fiction', label: 'Fiction' },
                  { value: 'Non-Fiction', label: 'Non-Fiction' },
                  { value: 'Story', label: 'Story' },
                  { value: 'Children', label: 'Children' }
                ]}
                placeholder="Select a content type"
                value={selectedContentType}
                onChange={(option) => setSelectedContentType(option)}

                styles={{
                  control: (base) => ({
                    ...base,
                    padding: '4px',
                    borderRadius: '4px',
                    border: '1px solid #ddd',
                    boxShadow: 'none',
                    '&:hover': {
                      border: '1px solid #FECB00'
                    }
                  }),
                  option: (base, state) => ({
                    ...base,
                    padding: '8px 12px',
                    backgroundColor: state.isSelected ? '#FECB00' : 'white',
                    color: state.isSelected ? 'black' : '#333',
                    '&:hover': {
                      backgroundColor: '#fff5cc'
                    }
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 9999
                  })
                }}
              />

              <div style={{ height: "20px" }}></div>

              <p style={{ color: "#909090" }}>Ebook description:</p>
              <div style={{ height: "10px" }}></div>
              <textarea
                className="input"
                placeholder="Enter a description for your ebook"
                value={description}
                onChange={(v) => setDescription(v.target.value)}
                rows={6}
                style={{ height: "150px", fontSize: "1.2em" }}
              />


              {errore ? <p style={{ color: "red", marginTop: "15px" }}>{errore}</p> : null}

              <div style={{ height: "15px" }}></div>

              <div className="field" style={{ display: 'flex', alignItems: 'center' }}>
                <p style={{ color: "#909090" }}>Ebook Script:</p>

                <label className="switch" style={{ marginLeft: "10px" }}>
                  <input
                    type="checkbox"
                    checked={showScript}
                    onChange={(e) => setShowScript(e.target.checked)}
                  />
                  <span className="slider round"></span>
                </label>

              </div>

              {showScript && (
                <>
                  <div style={{ height: "15px" }}></div>
                  <textarea
                    className="input"
                    placeholder="Enter your custom script for the ebook"
                    value={script}
                    onChange={(v) => setScript(v.target.value)}
                    rows={6}
                    style={{ height: "150px", fontSize: "1.2em" }}
                  />
                </>
              )}

              <div style={{ height: "10px" }}></div>

              <div className="button-container" style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#000",
                    borderRadius: "3px",
                  }}
                  onClick={() => setstep(0)}
                >
                  Back
                </button>

                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#000",
                    background: "#FECB00",
                    borderRadius: "3px",
                  }}
                  onClick={() => next(1)}
                >
                  Next
                </button>
              </div>


            </div>

            <div id="step3" style={{ display: step === 2 ? 'block' : 'none' }}>

              <div style={{ padding: "20px 0" }}>
                <p style={{ color: "#909090", marginBottom: "20px" }}>
                  Select the number of chapters: <strong>{chapters}</strong>
                </p>

                <div style={{ position: "relative", width: "100%" }}>
                  <input
                    type="range"
                    min="6"
                    max="18"
                    step="1"
                    value={chapters}
                    onChange={(e) => {
                      const value = parseInt(e.target.value);
                      setChapters(value);
                      setCredits(calculateCredits(value));
                    }}
                    style={{
                      width: "100%",
                      height: "12px",
                      background: `linear-gradient(to right, 
          #FECB00 0%, 
          #FECB00 ${((chapters - 6) * 100) / 12}%, 
          #e0e0e0 ${((chapters - 6) * 100) / 12}%, 
          #e0e0e0 100%)`,
                      appearance: "none",
                      outline: "none",
                      borderRadius: "2px"
                    }}
                  />

                  <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "8px",
                    color: "#909090",
                    fontSize: "0.9em",
                    position: "relative",
                    width: "100%"
                  }}>
                    <span>6</span>
                    <span>8</span>
                    <span>10</span>
                    <span>12</span>
                    <span>14</span>
                    <span>16</span>
                    <span>18</span>
                  </div>
                </div>

                <div style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "20px",
                  padding: "8px 16px",
                  background: "#1a1a1a",
                  borderRadius: "25px",
                  width: "fit-content",
                  margin: "20px auto"
                }}>
                  <span style={{ color: "white", marginRight: "8px" }}>You will need</span>
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    background: "transparent",
                    padding: "4px 12px 4px 0px",
                    borderRadius: "20px"
                  }}>
                    <span style={{
                      color: "#FECB00",
                      marginRight: "4px",
                      fontSize: "1.2em"
                    }}>●</span>
                    <span style={{ color: "white" }}>{credits} credits</span>
                  </div>
                </div>
              </div>

              <div style={{ height: "20px" }}></div>

              {errore ? <p style={{ color: "red", marginTop: "15px" }}>{errore}</p> : null}


              <div className="button-container" style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#fff",
                    background: "#000",
                    borderRadius: "3px",
                  }}
                  onClick={() => setstep(1)}
                >
                  Back
                </button>

                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#000",
                    background: "#FECB00",
                    borderRadius: "3px",
                  }}
                  onClick={() => next(2)}
                >
                  Next
                </button>
              </div>



            </div>


            <div id="step5" style={{ display: step === 3 ? 'block' : 'none' }}>


              <h2 style={{ fontWeight: "600", fontSize: "1.1em" }}>Congratulations! Your ebook is being created!</h2>
              <p style={{ color: "#909090" }}>Click the button below to check the status of your new ebook.</p>
              <div style={{ height: "20px" }}></div>

              <div className="wrapperan">
                <svg className="animated-check" viewBox="0 0 24 24">
                  <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" /> </svg>
              </div>

              <div style={{ height: "20px" }}></div>

              <div className="button-container" style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <button
                  style={{
                    padding: "10px 15px",
                    fontSize: "1.1rem",
                    fontWeight: "600",
                    border: 0,
                    color: "#000",
                    background: "#FECB00",
                    borderRadius: "3px",
                  }}
                  onClick={() => {
                    resetCreationFn();
                    history.push({
                      pathname: '/ebooks',
                    })
                  }
                  }
                >
                  Go to your Ebooks
                </button>
              </div>


            </div>


          </div>

        </div>
      </div>
      <div style={{ height: "20px" }}></div>
      <div className="columns" style={{ margin: 13 }}>
        <p style={{
          color: "black",
        }}>© 2025 EasyEbook</p>
      </div>

      <Popup
        ref={canvaRef}
        contentStyle={{/*margin:"auto 10px"*/ }}
      >

        <div style={{ padding: "30px 20px" }}>
          <div style={{ width: "300px" }}><p style={{ color: "#333", fontWeight: "600", textAlign: "center", fontSize: "1em" }}>Sign in or Register:
          </p>
            <div style={{ height: "15px" }}></div>

            <p style={{ color: "#333", fontWeight: "400", textAlign: "center", fontSize: "1em" }}>
              You need to be logged in to create a new ebook.</p>

            <div style={{ height: "15px" }}></div>


            <div className="" style={{ maxWidth: "300px", color: "white", fontSize: "0.8em", textAlign: "center", fontWeight: 600 }}> <button className="" style={{
              border: 0,
              padding: "5px 15px", lineHeight: "30px", background: "#000", color: "white", borderRadius: "3px"
            }} onClick={() => {

              history.push({
                pathname: '/login',
                state: {
                  fromTutorial: true, selectedLanguage: selectedLanguage.value, author, selectedContentType: selectedContentType.value, description, script, chapters
                }
              })



              //canvaRef.current.close();
              //addCustomContent();

            }}><b>I already have an account</b></button></div>

            <div style={{ height: "15px" }}></div>


            <div className="" style={{ maxWidth: "300px", color: "white", fontSize: "0.8em", textAlign: "center", fontWeight: 600 }}> <button className="" style={{
              border: 0,
              padding: "5px 15px", lineHeight: "30px", background: "#fecb00", color: "#000", borderRadius: "3px"
            }} onClick={() => {


              history.push({
                pathname: '/register',
                state: {
                  fromTutorial: true, selectedLanguage: selectedLanguage.value, author, selectedContentType: selectedContentType.value, description, script, chapters
                }
              })
              //addCustomContent();

            }}><b>Create a New Account</b></button></div>


          </div>

          <div style={{ height: "15px" }}></div>



        </div>
      </Popup >


      <Popup
        ref={payRef}
        contentStyle={{
          padding: "30px 20px",
          width: windowWidth <= 768 ? "100%" : "1160px", // Fixed width
          margin: "auto",
          background: "#000",
          borderRadius: "5px",
          border: "0px solid #fff",
          boxSizing: "border-box",
          maxHeight: windowWidth <= 768 ? "100vh" : "auto",
          overflowY: windowWidth <= 768 ? "auto" : "visible"
        }}
      >
        <div style={{
          width: windowWidth <= 768 ? "100%" : "800px",
          margin: "0 auto",
          padding: windowWidth <= 768 ? "0 15px" : "0"
        }}>
          <h2 style={{
            textAlign: "center",
            fontSize: "2em",
            marginBottom: "20px",
            color: "#fff"
          }}>You don't have enough credits</h2>

          <div style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "30px",
            background: "#f5f5f5",
            padding: "5px",
            borderRadius: "25px",
            width: windowWidth <= 768 ? "100%" : "300px",
            margin: "0 auto 30px"
          }}>
            <button
              onClick={() => { if (!ispaying) setShowCredits(false) }}
              style={{
                padding: "8px 20px",
                border: "none",
                borderRadius: "20px",
                background: !showCredits ? "#FECB00" : "transparent",
                color: !showCredits ? "#000" : "#666",
                cursor: "pointer",
                fontWeight: "600",
                flex: 1
              }}
            >
              Plans
            </button>
            <button
              onClick={() => setShowCredits(true)}
              style={{
                padding: "8px 20px",
                border: "none",
                borderRadius: "20px",
                background: showCredits ? "#FECB00" : "transparent",
                color: showCredits ? "#000" : "#666",
                cursor: "pointer",
                fontWeight: "600",
                flex: 1
              }}
            >
              Credits
            </button>
          </div>

          <div style={{
            display: "flex",
            justifyContent: "center",
            width: "100%"
          }}>
            {!showCredits ? (
              // Plans view
              <div style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                gap: "30px",
                flexDirection: windowWidth <= 768 ? "column" : "row"
              }}>
                <div style={{
                  background: "#fff",
                  borderRadius: "10px",
                  padding: "24px",
                  width: windowWidth <= 768 ? "100%" : "340px",
                  boxShadow: "0px 0px 15px rgba(0,0,0,0.1)",
                  position: "relative"  // Add this to make it the positioning context
                }}>
                  <div style={{
                    background: "#FECB00",
                    padding: "8px 16px",
                    borderRadius: "20px",
                    width: "fit-content",
                    position: "absolute",  // Change to absolute
                    top: "-12px",         // Move it up slightly
                    left: "50%",          // Center horizontally
                    transform: "translateX(-50%)",  // Perfect centering
                    zIndex: 1
                  }}>
                    <span style={{ color: "#000" }}>Most Popular</span>
                  </div>

                  <div style={{ height: "25px" }}></div>

                  <h3 style={{ color: "#000", fontSize: "1.8em", marginBottom: "8px" }}>EasyEbook Pro</h3>
                  <p style={{ color: "#666", marginBottom: "16px" }}>20 Credits/Month</p>
                  <h4 style={{ color: "#000", fontSize: "2em", marginBottom: "24px" }}>$20.00/Month</h4>

                  <button style={{
                    width: "100%",
                    padding: "12px",
                    background: "#FECB00",
                    border: "none",
                    borderRadius: "8px",
                    color: "#000",
                    fontSize: "1.1em",
                    cursor: "pointer",
                    marginBottom: "24px",
                    fontWeight: "600",
                    disabled: waitinglink
                  }} onClick={() => {
                    setWaitinglink(true);
                    subscribeFn();
                  }}>
                    {waitinglink ? <i className="fa fa-spinner fa-spin"></i> : "Subscribe"}
                  </button>

                  <ul style={{ color: "#000", listStyle: "none", padding: 0 }}>
                    {[
                      "20 Credits",
                      "Premium Version (GPT-o1)",
                      "Export in Canva",
                      "Multiple languages",
                      "Up to 18 chapters",

                    ].map((feature, index) => (
                      <li key={index} style={{ marginBottom: "12px" }}>
                        <span style={{ color: "#FECB00", marginRight: "8px" }}>✓</span>
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ) : (
              <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                gap: "30px"
              }}>
            <div style={{
              color: "#fff",
              textAlign: "center",
            }}>

              <p style={{
                fontSize: "1.1em",
                color: "#fff"
              }}>
                <b style={{ color: "#FECB00", fontSize: "1.2em" }}>🔥 Limited Time Offer</b><br />
                {/* Offer ends in: {String(timeLeft.hours).padStart(2, '0')}:{String(timeLeft.minutes).padStart(2, '0')}:{String(timeLeft.seconds).padStart(2, '0')}} */}
              </p>
            </div>

            <div style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              gap: "30px",
              flexWrap: "wrap"
            }}>
              {[
                { name: "Basic", credits: "10 CREDITS", price: "$19.99", isOff: true, originalPrice: "$39.90", credits_no: 10 },
                { name: "Essential", credits: "20 CREDITS", price: "$59.90", isOff: false, credits_no: 20 }
              ].map((plan, index) => (
                <div key={index} style={{
                  background: "#fff",
                  borderRadius: "10px",
                  padding: "24px",
                  width: windowWidth <= 768 ? "100%" : "340px",
                  boxShadow: "0px 0px 15px rgba(0,0,0,0.1)",
                  position: "relative"  // Add this to make it the positioning context
                }}>
                  {plan.isOff && (
                    <div style={{
                      background: "#FECB00",
                      padding: "8px 16px",
                      borderRadius: "20px",
                      width: "fit-content",
                      position: "absolute",  // Change to absolute
                      top: "-12px",         // Move it up slightly
                      left: "50%",          // Center horizontally
                      transform: "translateX(-50%)",  // Perfect centering
                      zIndex: 1            // Ensure it stays on top
                    }}>
                      <span style={{ color: "#000" }}>50% OFF</span>
                    </div>
                  )}

                  <h3 style={{ color: "#000", fontSize: "1.8em", marginBottom: "8px" }}>{plan.name}</h3>
                  <p style={{ color: "#666", marginBottom: "16px" }}>{plan.credits}</p>
                  <div style={{ marginBottom: "24px" }}>
                    {plan.isOff && (
                      <span style={{
                        color: "#666",
                        textDecoration: "line-through",
                        marginRight: "8px"
                      }}>{plan.originalPrice}</span>
                    )}
                    <span style={{ color: "#000", fontSize: "2em" }}>{plan.price}</span>
                  </div>

                  <button style={{
                    width: "100%",
                    padding: "12px",
                    background: "#FECB00",
                    border: "none",
                    borderRadius: "8px",
                    color: "#000",
                    fontSize: "1.1em",
                    cursor: "pointer",
                    marginBottom: "24px",
                    fontWeight: "600",
                    disabled: waitinglink
                  }} onClick={() => {
                    setWaitinglink(true);
                    getStripeFn(null, plan.credits_no);
                  }}>
                    {waitinglink ? <i className="fa fa-spinner fa-spin"></i> : "Buy credits"}
                  </button>

                  <ul style={{ color: "#000", listStyle: "none", padding: 0 }}>
                    {[
                      "Up to 18 chapters",
                      "Multiple languages",
                      "Edit Ebook",
                      "Export to Canva",
                      "No watermark"
                    ].map((feature, idx) => (
                      <li key={idx} style={{ marginBottom: "12px" }}>
                        <span style={{ color: "#FECB00", marginRight: "8px" }}>✓</span>
                        {feature}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
            )}
          </div>
    </div>
      </Popup >




    </div >);

}

const mapStateToProps = (state) => {
  return {

    token: state.home.token,
    error_in_creation: state.home.error_in_creation,
    sub_niches: state.home.sub_niches,
    titles: state.home.titles,
    preview: state.home.preview,
    queued: state.home.queued,
    balance: state.home.balance,
    ispaying: state.home.ispaying,
    willcancel: state.home.willcancel,
    redirect_url: state.home.redirect_url






  };
};

const mapDispatchToProps = (dispatch) => {
  return {

    sendStepFN: (i, v1, v2, v3, v4, v5) =>
      dispatch(sendStep(i, v1, v2, v3, v4, v5)),
    resetErrorsFn: () => dispatch(resetErrors()),
    resetCreationFn: () =>
      dispatch(resetCreation()),
    createEbookFn: (selectedLanguage, author, selectedContentType, description, script, chapters) =>
      dispatch(createEbook(selectedLanguage, author, selectedContentType, description, script, chapters)),
    getBalanceFn: () => dispatch(getBalance()),
    subscribeFn: () => dispatch(subscribe()),
    getStripeFn: (price, amount) => dispatch(getStripe(price, amount)),

  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(CreateEbook);
