import React, { useEffect, useState, useRef } from "react";

import { checkUsername, resetErrors, loginGoogle } from "../store/actions/homeActions";

import logo from "../assets/images/logo_3445.svg";


import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

import { connect } from "react-redux";
import { compose } from "redux";

import { Link, useLocation } from "react-router-dom";

import { Redirect } from 'react-router-dom';




function Register(props) {

  //const [step, setstep] = useState(0);

  const location = useLocation();
  const { fromTutorial, selectedLanguage, author, selectedContentType, description, script, chapters } = location.state || {};


  const { token, checkUsernameFn, errore, resetErrorsFn, loggedin_doi, loginGoogleFN } = props;

  const [username, setusername] = useState(null);
  const [name, setname] = useState(null);
  const [password1, setpassword1] = useState(null);
  const [password2, setpassword2] = useState(null);

  const componentRef = useRef(null);
  const [width, setWidth] = useState(0);

  //const new_lang = languageService.getLanguage();


  useEffect(() => {
    if (componentRef.current) {
      const handleResize = () => {
        console.log(componentRef.current.getBoundingClientRect().width);
        setWidth(componentRef.current.getBoundingClientRect().width);
      };

      // Set initial width
      handleResize();

      // Add resize event listener
      window.addEventListener('resize', handleResize);

      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);


  const handleSubmit = (e) => {

    e.preventDefault();


    checkUsernameFn(username, name, password1, password2);




  };

  useEffect(() => {

    setTimeout(() => {
      resetErrorsFn();
    }, 8000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errore]);



  useEffect(() => {

    if (loggedin_doi) {
      //setstep(2);
      setTimeout(() => {
        resetErrorsFn();
      }, 8000);

    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedin_doi]);


  const onLoginSuccess = async (response) => {

    loginGoogleFN(response.credential);

  };

  const GoogleSignInButton = ({ onLoginSuccess }) => {
    return (
      <GoogleOAuthProvider style={{width:"100%"}} clientId="604838523517-ogr2e0r1fqia3dja624jjmmkp2eelh83.apps.googleusercontent.com">
        <GoogleLogin
          onSuccess={onLoginSuccess}
          onError={() => {
            console.log('Login Failed');
          }}
          width={width}
          />
      </GoogleOAuthProvider>
    );
  };

  
  return token && !fromTutorial ? <Redirect to="/ebooks" /> : token && fromTutorial ? <Redirect to={{ pathname: "/create", state: { daje: true, selectedLanguage2: selectedLanguage, author2: author, selectedContentType2: selectedContentType, description2: description, script2: script, chapters2: chapters } }} /> : (


  //return token ? <Redirect to="/ebooks" /> : (
    <div className="container" style={{ marginTop: "60px" }}>
      <div className="columns is-centered" style={{ margin: 13, background: "white", borderRadius: "3px" }}>
        <div
          className="column is-half"
          style={{
            color: "black",
            padding: "10px !important",
          }}
        >
          <form onSubmit={handleSubmit} style={{}}>
            <div style={{ height: "10px" }}></div>

            <p className="has-text-centered"><Link to="/"><img src={logo} className="logonav2" alt="Logo" /></Link></p>

            <h1 className="has-text-centered">Create an account:</h1>
            <div style={{ height: "30px" }}></div>
            <div className="field">
              <p className="control has-icons-left">
                <input
                  required
                  className="input"
                  type="text"
                  placeholder={"Your e-mail address"}
                  id="username"
                  name="username"
                  onChange={(v) => {
                    setusername(v.target.value);
                  }}
                  style={{ color: "#000000", fontSize: "1.2em" }}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-envelope"></i>
                </span>
              </p>
            </div>


            <div className="field">
              <p className="control has-icons-left">
                <input
                  required
                  className="input"
                  type="text"
                  placeholder={"Enter your full name"}
                  id="name"
                  name="name"
                  onChange={(v) => {
                    setname(v.target.value);
                  }}
                  style={{ color: "#000000", fontSize: "1.2em" }}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-user"></i>
                </span>
              </p>
            </div>


            <div className="field">
              <p className="control has-icons-left">
                <input
                  required
                  className="input"
                  type="password"
                  placeholder={"Enter a password"}
                  id="password1"
                  name="password1"
                  onChange={(v) => {
                    setpassword1(v.target.value);
                  }}
                  style={{ color: "#000000", fontSize: "1.2em" }}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-lock"></i>
                </span>
              </p>
            </div>


            <div className="field">
              <p className="control has-icons-left">
                <input
                  required
                  className="input"
                  type="password"
                  placeholder={"Repeat your password"}
                  id="password2"
                  name="password2"
                  onChange={(v) => {
                    setpassword2(v.target.value);
                  }}
                  style={{ color: "#000000", fontSize: "1.2em" }}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-lock"></i>
                </span>
              </p>
            </div>


            <div style={{ height: "5px" }}></div>


            <label className="checkbox">
              <input type="checkbox"
                required
              />
              &nbsp;<i>I have read the <Link to="/terms">Terms and Conditions</Link> and the <Link to="/privacy">Privacy Policy</Link></i>.
            </label>

            <div style={{ height: "20px" }}></div>

            <div className="field">
              <button
                style={{
                  padding: "10px 15px",
                  fontSize: "1.1rem",
                  fontWeight: "600",
                  border: 0,
                  color: "#000",
                  background: "#FECB00",
                  borderRadius: "3px",
                  width: "100%"
                }}
                ref={componentRef}

              >
                Create an Account
              </button>

              <div style={{ height: "10px" }}></div>

              {/*<button
                style={{
                  padding: "10px 15px",
                  fontSize: "1.1rem",
                  fontWeight: "600",
                  border: 0,
                  color: "#000",
                  background: "#eeeeee",
                  borderRadius: "3px",
                  width: "100%"
                }}
              >
                <img style={{ verticalAlign: "bottom" }} alt="Google" src={google} /> {"  "}Sign in with Google
              </button>*/}

              <div className="button-container2"><GoogleSignInButton onLoginSuccess={onLoginSuccess}></GoogleSignInButton></div>

            </div>

            {errore ? <p style={{ color: "red" }}>{errore}</p> : null}
          </form>
          <div style={{ height: "20px" }}></div>

          <p className="has-text-centered">Already have an account? Click <Link to="/login" style={{ color: "#000", fontWeight: 600 }}>here</Link> to sign in.</p>

        </div>
      </div>
    </div>);

}

const mapStateToProps = (state) => {
  return {
    errore: state.home.errore,
    loggedin: state.home.loggedin,
    loggedin_doi: state.home.loggedin_doi,
    token: state.home.token

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    checkUsernameFn: (username, name, pwd1, pwd2) => dispatch(checkUsername(username, name, pwd1, pwd2)),
    resetErrorsFn: () => dispatch(resetErrors()),
    loginGoogleFN: (a) => dispatch(loginGoogle(a)),




  };
};


export default compose(connect(mapStateToProps, mapDispatchToProps))(Register);
